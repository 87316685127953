<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2> المنتجات المقاطعة </h2>
      </v-col>
      <v-col cols="12" sm="12">
        <form @submit.prevent="submit" enctype="multipart/form-data" method="POST" >
          <v-card elevation="3">
             <v-col cols="12">
        <h2 class="text-center">تعديل على المنتج المقاطع  </h2>
      </v-col>
      <v-card-text>
        <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-text-field
                                        label="  اسم المنتج المقاطع "
                                        name="name"
                                        v-model="form.name"
                                        clearable
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-text-field
                                        label="  اسم المنتج المقاطع انجليزي  "
                                        name="name_en"
                                        v-model="form.name_en"
                                        clearable
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <v-select
                                        :items="boycott_categories"
                                        v-model="form.boycott_categories_id"
                                        label="الصنف "
                                        name="boycott_categories_id"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                        clearable
                                        outlined />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <v-select
                                        :items="boycott_companies"
                                        name="boycott_companies_id"
                                        v-model="form.boycott_companies_id"
                                        item-text="name"
                                        item-value="id"
                                        label="الشركة المقاطعة  "
                                        dense
                                        clearable
                                        outlined/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-text-field
                                        label="الباركود  "
                                        name="pr_code"
                                        clearable
                                        v-model="form.pr_code"
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-text-field
                                        label="الكيو ار  "
                                        name="qr_code"
                                        clearable
                                        type="number"
                                        v-model="form.qr_code"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="تفاصيل  المنتج "
                                        filled
                                        name="details"
                                        v-model="form.details"
                                        auto-grow
                                        rows="4"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="تفاصيل المنتج بالغة الانجليزية"
                                        filled
                                        name="details_en"
                                        v-model="form.details_en"
                                        auto-grow
                                        rows="4"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="سبب المقاطعة  "
                                        filled
                                        name="reason_boycott"
                                        v-model="form.reason_boycott"
                                        auto-grow
                                        rows="2"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="سبب المقاطعة بالعة الانجليزية"
                                        filled
                                        name="reason_boycott_en"
                                        v-model="form.reason_boycott_en"
                                        auto-grow
                                        rows="2"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                  <input type="file" name="image"
                                   label=" صورة رئيسية للمنتج" @change="onChangeimage" />

                                    <!--v-file-input
                                        label=" صورة رئيسية للمنتج"
                                        outlined
                                        @change="onChangeimage"
                                        name="main_image"
                                        counter
                                        show-size
                                        dense
                                    ></v-file-input-->
                                </v-col>
                                <!-- <v-col cols="6" sm="6" md="6">
                                    <c-text-field
                                        label="الرقم"
                                        name="id"
                                        clearable
                                        v-model="form.id"
                                         hidden

                                    />
                                </v-col> -->
                            </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <InertiaLinkBtn
                :url="route('boycott_products.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"/>
              <v-btn type="submit" :loading="loading" color="primary"
                >حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";
import cTextField from "@/components/shared/cTextField";
export default {
  layout: layout,
  cTextField: cTextField,
  props: [
        "boycott_categories",
        "boycott_companies",
        "boycott_products",
    ],
  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
  },
  metaInfo() {
    return {
      title: "تعديل المنتج المقاطع",
    };
  },
    data() {
    return {
      loading: false,
      tab: null,
      boycott_categories: this.boycott_categories,
      boycott_companies: this.boycott_companies,
     //  form:this.boycott_products,
      form:{
        id: this.boycott_products.id || "",
        boycott_companies_id: this.boycott_products.boycott_companies_id || 0,
        boycott_categories_id: this.boycott_products.boycott_categories_id || 0,
        details: this.boycott_products.details || "",
        name: this.boycott_products.name || "",
        reason_boycott: this.boycott_products.reason_boycott || "",
        name_en: this.boycott_products.name_en || "",
        details_en: this.boycott_products.details_en || "",
        reason_boycott_en: this.boycott_products.reason_boycott_en || "",
        qr_code: this.boycott_products.qr_code || "",
        pr_code: this.boycott_products.pr_code || "",
     //    image: this.boycott_products.image || "",
      },
    };
  },
  methods: {

   onChangeimage(event) {
     console.log(event.target.files[0])
      const image = event.target.files[0];
      this.form.image = image;
     //  this.form.id = this.boycott_products.id ;


   },
    submit() {

     let config = { headers: { "content-type": "multipart/form-data" } };
          if(this.form.image){
                  this.$inertia.post(route("boycott_products.update2"),this.form, config, {
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
          }else{

               this.$inertia.post(route("boycott_products.update2"),this.form, {
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
          }

    },
  },

};
</script>
<style>
</style>
