<template>
  <v-navigation-drawer
    app
    id="appDrawer"
    :right="$vuetify.rtl"
    :dark="$vuetify.dark"
    v-bind="$attrs"
    v-on="$listeners">
   <v-list color="white" shaped>
     <InertiaLinkBtn
        title="لوحة التحكم"
        icon="fa-chart-pie"
        url="/"
        :color="!route().current('dashboard') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
        :class="route().current('dashboard') ? 'v-list-item--active primary' : ''" />

       <v-list-group
        prepend-icon="fa-list-alt"
        :value="route().current('.*')"
        v-if="can(['boycott_companies-create', 'boycott_companies-read'])" >
        <template v-slot:activator>
          <v-list-item-title>المقاطعة</v-list-item-title>
        </template>
        <InertiaLinkBtn
          v-if="can('boycott_companies-read')"
          title="الشركات المقاطعة"
          icon="fa-list-alt"
          :url="route('boycott_companies.index')"
          :color="!route().current('boycott_companies.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('boycott_companies.index') ? 'v-list-item--active primary theme--light' : ' '"
        />
                <InertiaLinkBtn
          v-if="can('boycott_categories-read')"
          title="الاصناف"
          icon="fa-list-alt"
          :url="route('boycott_categories.index')"
          :color="!route().current('boycott_categories.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('boycott_categories.index') ? 'v-list-item--active primary theme--light' : ' '"
        />
  
      </v-list-group>

      <v-list-group
        prepend-icon="fa-list-alt"
        :value="route().current('.*')"
        v-if="can(['boycott_products-create', 'boycott_products-read'])" >
        <template v-slot:activator>
          <v-list-item-title> المنتجات المقاطعة  </v-list-item-title>
        </template>
        <InertiaLinkBtn
          v-if="can('boycott_products-read')"
          title="عرض المنتجات المقاطعة "
          icon="fa-list-alt"
          :url="route('boycott_products.index')"
          :color="!route().current('boycott_products.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('boycott_products.index') ? 'v-list-item--active primary theme--light' : ' '"
        />
        <InertiaLinkBtn
          v-if="can('boycott_products-create')"
          title="اضافة منتج مقاطع  "
          icon="fa-plus"
          :url="route('boycott_products.create')"
          :color="!route().current('boycott_products.create') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class=" route().current('boycott_products.create') ? 'v-list-item--active primary theme--light' : '' "
        />
      </v-list-group>
      <v-list-group
        prepend-icon="fa-list-alt"
        :value="route().current('.*')"
        v-if="can(['calendar_tepyans-create', 'calendar_tepyans-read'])" >
        <template v-slot:activator>
          <v-list-item-title> التاريخ الميلادي  </v-list-item-title>
        </template>
        <InertiaLinkBtn
          v-if="can('calendar_tepyans-read')"
          title="عرض التاريخ الميلادي   "
          icon="fa-list-alt"
          :url="route('calendar_tepyans.index')"
          :color="!route().current('calendar_tepyans.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('calendar_tepyans.index') ? 'v-list-item--active primary theme--light' : ' '"
        />
        <InertiaLinkBtn
          v-if="can('calendar_tepyans-create')"
          title="اضافة تاريخ ميلادي  "
          icon="fa-plus"
          :url="route('calendar_tepyans.create')"
          :color="!route().current('calendar_tepyans.create') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class=" route().current('calendar_tepyans.create') ? 'v-list-item--active primary theme--light' : '' "
        />
      </v-list-group>
      <v-list-group
        prepend-icon="fa-list-alt"
        :value="route().current('.*')"
        v-if="can(['calendar_hijris-create', 'calendar_hijris-read'])" >
        <template v-slot:activator>
          <v-list-item-title>  التقويم الهجري  </v-list-item-title>
        </template>
        <InertiaLinkBtn
          v-if="can('calendar_hijris-read')"
          title="عرض  التقويم الهجري "
          icon="fa-list-alt"
          :url="route('calendar_hijris.index')"
          :color="!route().current('calendar_hijris.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('calendar_hijris.index') ? 'v-list-item--active primary theme--light' : ' '"
        />
        <InertiaLinkBtn
          v-if="can('calendar_hijris-create')"
          title="اضافة تاريخ هجري  "
          icon="fa-plus"
          :url="route('calendar_hijris.create')"
          :color="!route().current('calendar_hijris.create') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class=" route().current('calendar_hijris.create') ? 'v-list-item--active primary theme--light' : '' "
        />
      </v-list-group>


      <v-list-group
        prepend-icon="fa-list-alt"
        :value="route().current('.*')"
        v-if="can(['agricultural_calendars-create', 'agricultural_calendars-read'])" >
        <template v-slot:activator>
          <v-list-item-title>  المعالم الزراعية   </v-list-item-title>
        </template>
        <InertiaLinkBtn
          v-if="can('agricultural_calendars-read')"
          title="عرض المعالم الزراعية  "
          icon="fa-list-alt"
          :url="route('agricultural_calendars.index')"
          :color="!route().current('agricultural_calendars.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('agricultural_calendars.index') ? 'v-list-item--active primary theme--light' : ' '"
        />
        <InertiaLinkBtn
          v-if="can('agricultural_calendars-create')"
          title="اضافة  معلم زراعي  "
          icon="fa-plus"
          :url="route('agricultural_calendars.create')"
          :color="!route().current('agricultural_calendars.create') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class=" route().current('agricultural_calendars.create') ? 'v-list-item--active primary theme--light' : '' "
        />
      </v-list-group>
       <v-list-group
        prepend-icon="fa-list-alt"
        :value="route().current('.*')"
        v-if="can(['sliders-create', 'sliders-read'])" >
        <template v-slot:activator>
          <v-list-item-title> الاعلانات</v-list-item-title>
        </template>
        <InertiaLinkBtn
          v-if="can('sliders-read')"
          title="عرض الاعلانات"
          icon="fa-list-alt"
          :url="route('sliders.index')"
          :color="!route().current('sliders.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('sliders.index') ? 'v-list-item--active primary theme--light' : ' '"
        />
        <InertiaLinkBtn
          v-if="can('sliders-create')"
          title="اضافة اعلان  "
          icon="fa-plus"
          :url="route('sliders.create')"
          :color="!route().current('sliders.create') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class=" route().current('sliders.create') ? 'v-list-item--active primary theme--light' : '' "
        />
      </v-list-group>

     <v-list-group
        prepend-icon="fa-list-alt"
        :value="route().current('.*')"
        v-if="can(['events-create', 'events-read'])" >
        <template v-slot:activator>
          <v-list-item-title> الاحداث </v-list-item-title>
        </template>
        <InertiaLinkBtn
          v-if="can('events-read')"
          title="عرض الاحداث "
          icon="fa-list-alt"
          :url="route('events.index')"
          :color="!route().current('events.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('events.index') ? 'v-list-item--active primary theme--light' : ' '"
        />
        <InertiaLinkBtn
          v-if="can('events-create')"
          title="اضافة حدث  "
          icon="fa-plus"
          :url="route('events.create')"
          :color="!route().current('events.create') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class=" route().current('events.create') ? 'v-list-item--active primary theme--light' : '' "
        />
      </v-list-group>

      <v-list-group
        prepend-icon="fa-list-alt"
        :value="route().current('.*')"
        v-if="can(['phrases-create', 'phrases-read'])" >
        <template v-slot:activator>
          <v-list-item-title> العبارات </v-list-item-title>
        </template>
        <InertiaLinkBtn
          v-if="can('phrases-read')"
          title="عرض العبارات "
          icon="fa-list-alt"
          :url="route('phrases.index')"
          :color="!route().current('phrases.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('phrases.index') ? 'v-list-item--active primary theme--light' : ' '"
        />
        <InertiaLinkBtn
          v-if="can('phrases-create')"
          title="اضافة عبارة  "
          icon="fa-plus"
          :url="route('phrases.create')"
          :color="!route().current('phrases.create') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class=" route().current('phrases.create') ? 'v-list-item--active primary theme--light' : '' "
        />
      </v-list-group>

      <v-list-group
        prepend-icon="fa-list-alt"
        :value="route().current('.*')"
        v-if="can(['locations-create', 'locations-read'])" >
        <template v-slot:activator>
          <v-list-item-title> المناطق </v-list-item-title>
        </template>
        <InertiaLinkBtn
          v-if="can('locations-read')"
          title="عرض المناطق "
          icon="fa-list-alt"
          :url="route('locations.index')"
          :color="!route().current('locations.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('locations.index') ? 'v-list-item--active primary theme--light' : ' '"
        />
        <InertiaLinkBtn
          v-if="can('locations-create')"
          title="اضافة منطقة  "
          icon="fa-plus"
          :url="route('locations.create')"
          :color="!route().current('locations.create') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class=" route().current('locations.create') ? 'v-list-item--active primary theme--light' : '' "
        />
      </v-list-group>

      <v-list-group
        prepend-icon="fa-list-alt"
        :value="route().current('.*')"
        v-if="can(['prayer_times-create', 'prayer_times-read'])" >
        <template v-slot:activator>
          <v-list-item-title> مواقيت الصلاة </v-list-item-title>
        </template>
        <InertiaLinkBtn
          v-if="can('prayer_times-read')"
          title="عرض مواقيت الصلاة "
          icon="fa-list-alt"
          :url="route('prayer_times.index')"
          :color="!route().current('prayer_times.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('prayer_times.index') ? 'v-list-item--active primary theme--light' : ' '"
        />
        <InertiaLinkBtn
          v-if="can('prayer_times-create')"
          title="اضافة موقت صلاة  "
          icon="fa-plus"
          :url="route('prayer_times.create')"
          :color="!route().current('prayer_times.create') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class=" route().current('prayer_times.create') ? 'v-list-item--active primary theme--light' : '' "
        />
      </v-list-group>

      <v-list-group
        prepend-icon="fa-users"
        :value="route().current('.*')"
        v-if="can(['users-create', 'users-read'])"
      >
        <template v-slot:activator>
          <v-list-item-title>المستخدمين </v-list-item-title>
        </template>
        <InertiaLinkBtn
          v-if="can('users-read')"
          title="عرض المستخدمين"
          icon="fa-users"
          :url="route('users.index')"

          :color="!route().current('users.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"

          :class="
            route().current('users.index') ? 'v-list-item--active primary theme--light' : ' '
          "
        />

        <InertiaLinkBtn
          v-if="can('users-create')"
          title="اضافة مستخدم"
          icon="fa-plus"
          :url="route('users.create')"
            :color="!route().current('users.create') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"

          :class="
            route().current('users.create') ? 'v-list-item--active primary theme--light' : ''
          "
        />
      </v-list-group>

      <v-list-group
        prepend-icon="fa-lock"
        :value="route().current('roles.*')"
        v-if="can(['roles-create', 'roles-read'])">
        <template v-slot:activator>
          <v-list-item-title> الصلاحيات </v-list-item-title>
        </template>
        <InertiaLinkBtn
          v-if="can('roles-read')"
          title="عرض الادوار / الصلاحيات"
          icon="fa-lock-open"
          :url="route('roles.index')"
           :color="!route().current('roles.index') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('roles.index') ? 'v-list-item--active primary theme--light' : ''"/>
        <InertiaLinkBtn
          v-if="can('roles-create')"
          title="اضافة دور"
          icon="fa-key"
          :url="route('roles.create')"
          :color="!route().current('roles.create') ? ' v-btn v-list-item primary--text':' v-btn v-list-item'"
          :class="route().current('roles.create') ? 'v-list-item--active primary theme--light' : ''"/>
      </v-list-group>

      <c-inertia-link-item
        v-if="can('settings-read')"
        :href="route('settings.index')"
        icon="fa-cogs"
        title="الأعددات  "
        :class="route().current('settings.index')
            ? 'v-list-item--active primary '
            : 'primary--text'"
        @close-drawer="$emit('close-drawer')"
      />
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import InertiaLinkBtn from "@/components/InertiaLinkBtn";
export default {
  components: {
    InertiaLinkBtn,
  },
  data() {
      return {
      // appTitle: this.$page.props.company.name || "  ",
      menuItems: [
        //    { title: 'لوحة التحكم', path: route("dashboard"),route_name:"dashboard", icon: 'fa-chart-pie' },
      ],
    };
  },
};
</script>
<style>
.v-application--is-rtl
  .v-list-group--no-action.v-list-group--sub-group
  > .v-list-group__items
  > .v-list-item {
  padding-right: 40px;
}

.font-size-17px {
  font-size: 17px;
}
</style>
