<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2> تقويم تبيان </h2>
      </v-col>
      <v-col cols="12" sm="12">
        <form @submit.prevent="submit" enctype="multipart/form-data" method="POST" >
          <v-card elevation="3">
             <v-col cols="12">
        <h2 class="text-center">تعديل   تقويم تبيان  </h2>
      </v-col><v-card-text>
                         <v-row>
                                             <v-col  cols="4" sm="4" md="4">
                                             <v-select
                                                  :items="month_my"
                                                  item-value="id"
                                                  item-text="name"
                                                  name="monthm"
                                                  v-model="form.monthm"
                                                  outlined
                                                  dense
                                                  clearable
                                                  label=" الشهر الميلادي "/>
                                             </v-col>
                                             <v-col  cols="4" sm="4" md="4">
                                             <v-select

                                                  :items="monthc"
                                                  item-value="id"
                                                  item-text="id"

                                                  label= "اليوم الميلادي"
                                                  name="daym"
                                                  v-model="form.daym"
                                                  outlined
                                                  dense
                                                  clearable />
                                             </v-col>
                                             <v-col  cols="4" sm="4" md="4">
                                             <v-select
                                                  :items="seasons"
                                                  item-value="id"
                                                  item-text="name"
                                                  name="season"
                                                  v-model="form.season"
                                                  outlined
                                                  dense
                                                  clearable
                                                  label=" الفصل  "/>
                                             </v-col>
                         </v-row>
                            <v-row>
                                <v-col  cols="4" sm="4" md="4">
                                <v-select
                                    :items="monthromy"
                                    item-value="id"
                                    item-text="name"
                                    name="monthromy"
                                   v-model="form.monthromy"
                                    outlined
                                    dense
                                    clearable
                                    label=" الشهر الروماني  "/>
                                </v-col>
                                <v-col  cols="4" sm="4" md="4">
                                <v-select

                                    :items="monthc"
                                    item-value="id"
                                    item-text="id"

                                     label= "اليوم الروماني"
                                    name="dayromy"
                                    v-model="form.dayromy"
                                    outlined
                                    dense
                                    clearable />
                                </v-col>
                                <v-col  cols="4" sm="4" md="4">
                                <v-select
                                    :items="monthgriny"
                                    item-value="id"
                                    item-text="name"
                                    name="monthgriny"
                                   v-model="form.monthgriny"
                                    outlined
                                    dense
                                    clearable
                                    label=" الشهر القراني  "/>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col  cols="3" sm="3" md="3">
                                <v-select
                                    :items="monthhims"
                                    item-value="name"
                                    item-text="name"
                                    name="monthhim"
                                   v-model="form.monthhim"
                                    outlined
                                    dense
                                    clearable
                                    label=" الشهر الحميري "/>
                                </v-col>
                                <v-col  cols="3" sm="3" md="3">
                                <v-select

                                :items="monthc"
                                    item-value="id"
                                    item-text="id"

                                     label= "اليوم الحميري"
                                    name="dayhim"
                                    v-model="form.dayhim"
                                    outlined
                                    dense
                                    clearable />
                                </v-col>
                                <v-col  cols="3" sm="3" md="3">
                                <v-select
                                    :items="monthsuns"
                                    item-value="name"
                                    item-text="name"
                                    name="monthsun"
                                   v-model="form.monthsun"
                                    outlined
                                    dense
                                    clearable
                                    label=" منزلة الشمس  "/>
                                </v-col>
                                <v-col  cols="3" sm="3" md="3">
                                <v-select

                                :items="monthc"
                                    item-value="id"
                                    item-text="id"

                                     label= "يوم منزلة الشمس"
                                    name="daysun"
                                    v-model="form.daysun"
                                    outlined
                                    dense
                                    clearable />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col  cols="3" sm="3" md="3">
                                <v-select
                                    :items="monthzss"
                                    item-value="name"
                                    item-text="name"
                                    name="monthzs"
                                   v-model="form.monthzs"
                                    outlined
                                    dense
                                    clearable
                                    label=" الشهر الزراعي صنعاء "/>
                                </v-col>
                                <v-col  cols="3" sm="3" md="3">
                                <v-select

                                :items="monthc"
                                    item-value="id"
                                    item-text="id"

                                     label= "اليوم"
                                    name="dayzs"
                                    v-model="form.dayzs"
                                    outlined
                                    dense
                                    clearable />
                                </v-col>
                                <v-col  cols="3" sm="3" md="3">
                                <v-select
                                    :items="monthzhs"
                                    item-value="name"
                                    item-text="name"
                                    name="monthzh"
                                   v-model="form.monthzh"
                                    outlined
                                    dense
                                    clearable
                                    label=" الشهر الزراعي حضرموت "/>
                                </v-col>
                                <v-col  cols="3" sm="3" md="3">
                                <v-select

                                :items="monthc"
                                    item-value="id"
                                    item-text="id"


                                     label= "اليوم"
                                    name="dayzh"
                                    v-model="form.dayzh"
                                    outlined
                                    dense
                                    clearable />
                                </v-col>
                            </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <InertiaLinkBtn
                :url="route('calendar_tepyans.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"/>
              <v-btn type="submit" :loading="loading" color="primary"
                >حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";
import { month_my,seasons,yearms,monthsuns,monthhims ,monthzss,monthzhs,monthgriny,monthromy,monthc} from "@/data/temp.js";
export default {
  layout: layout,
  props: ["calendar_tepyans"],
  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
  },
  metaInfo() {
    return {
      title: "تعديل اعلان  ",
    };
  },
  methods: {
    submit() {
    //   let config = {headers:{'content-type': 'multipart/form-data',}};
      this.$inertia.put(route("calendar_tepyans.update",this.form.id), this.form, {
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
  data() {
    return {
      loading: false,
      tab: null,
            month_my,
            seasons,
            yearms,
            monthhims,
            monthsuns,
            monthzss,
            monthzhs,
            monthromy,
            monthgriny,
            monthc,
      form:this.calendar_tepyans,
    };
  },
};
</script>
<style>
</style>
