<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2> الاعلانات</h2>
      </v-col>
      <v-col cols="12" sm="12">
        <form @submit.prevent="submit" enctype="multipart/form-data" method="POST" >
          <v-card elevation="3">
             <v-col cols="12">
        <h2 class="text-center">إضافة اعلان  جديد </h2>
      </v-col>
     <v-card-text>
          <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-text-field
                                        label="عنوان العبارة"
                                        name="title"
                                        v-model="form.title"
                                        clearable
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-text-field
                                        label="عنوان العبارة بالغة الانجليزية"
                                        name="title_en"
                                        v-model="form.title_en"
                                        clearable
                                    />
                                </v-col>
          </v-row>



          <v-row >
              <v-col cols="3" sm="3" md="3">
              <c-text-field
            label=" تاريخ بداية الاعلان "
            type="date"
            name="date_start"
            v-model="form.date_start"/>
              </v-col>

              <v-col  cols="3" sm="3" md="3">
                                    <c-text-field v-if="form.date_start"
                                        label="يبداء  من وقت"
                                        type="time"
                                        name="time_start"
                                        v-model="form.time_start"
                                        clearable
                                    />
                                </v-col>

              <v-col cols="3" sm="3" md="3">
            <c-text-field
            label=" تاريخ نهاية الاعلان "
            type="date"
            name="date_end"
            v-model="form.date_end"/>
              </v-col>

              <v-col cols="3" sm="3" md="3">
                                    <c-text-field v-if="form.date_end"
                                        label="ينتهي  في وقت"
                                        type="time"
                                        name="time_end"
                                        v-model="form.time_end"
                                        clearable
                                    />
                                </v-col>
          </v-row>

                                 <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="التفاصيل"
                                        filled
                                        name="describtion"
                                        v-model="form.describtion"
                                        auto-grow
                                        rows="4"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="التفاصيل  بالغة الانجليزية"
                                        filled
                                        name="describtion_en"
                                        v-model="form.describtion_en"
                                        auto-grow
                                        rows="4"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                            </v-row>





     </v-card-text>

          <v-card-actions>
              <v-spacer/>
              <InertiaLinkBtn
                :url="route('sliders.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"/>
              <v-btn type="submit" :loading="loading" color="primary"> حفظ </v-btn>
          </v-card-actions>

          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";

//
// import { db } from "@/Pages/fb";
// import db from '@/Pages/Admin/Slider/fb.js'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwbI0tFrjM6PiD65D3gbHu3L5yFZ99W6E",
  authDomain: "tapyan-81697.firebaseapp.com",
  databaseURL: "https://tapyan-81697-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tapyan-81697",
  storageBucket: "tapyan-81697.appspot.com",
  messagingSenderId: "1023451489298",
  appId: "1:1023451489298:web:0667a9ec7eecd8d3dfa4cb",
  measurementId: "G-62NGY0KER2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getAnalytics(app);

// const app =initializeApp(firebaseConfig);
// const db = app.firestore();
// db.settings({ timestampsInSnapshots: true });


export default {
  layout: layout,

  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
  },
    data() {
    return {
      loading: false,
      tab: null,
      form:{
       title:"",
       title_en:"",
       describtion:"",
       describtion_en:"",
       url:"",
       date_start:"",
       date_end:"",
       time_start:"",
       time_end:"",
      },
    };
  },

  metaInfo() {
    return {
      title: "اضافة اعلان  ",
    };
  },
  methods: {
    submit() {
          // console.log('rrrrrrrrrrrrrrrrrrrrr')
        const project = {
       title: this.form.title,
       id:2,
       describtion: this.form.describtion,
       date_start: this.form.date_start,
       date_end: this.form.date_end,
       time_start: this.form.time_start,
       time_end: this.form.time_end,
        }
        db.collection('projects').add(project).then(() => {
          console.log('added to db')
        })
     //  this.$inertia.post(route("sliders.store"),this.form,{
     //    onProgress: (progress) => {
     //      this.loading = true;
     //    },
     //    onSuccess: (page) => {
     //      this.loading = false;
     //    },
     //    onFinish: () => {
     //      this.loading = false;
     //    },
     //  });
    },
  },
};
</script>

<style>
</style>
