<template>
    <the-datatable
    title="المنتجات المقاطعة  "
    :headers="headers"
      :nametable="nametable"
       :dataSource="boycott_products">



    </the-datatable>
</template>

<script>
import layout from "@/Layouts/Layout";

// import TheDatatable from "@/components/admin/TheDatatable";
export default {
    layout: layout,
    components: {TheDatatable: () => import("@/components/admin/TheDatatable")},
    props: ["boycott_products"],
    metaInfo() {
        return {
            title: "المنتجات المقاطعة ",
        }
    },

    methods: {

    },
    data() {
        return {



    // boycott_companies_id
    // boycott_categories_id
    // name
    // details
    // reason_boycott
    // name_en
    // details_en
    // reason_boycott_en
    // qr_code
    // pr_code
    // image
      nametable: "boycott_products",
            headers: [
                {
                    text: "الرقم ",
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "10%",
                },
                {text: "الاسم ", value: "name", width: "10%", align: "center"},
                {text: "الباركود", value: "qr_code", width: "10%", align: "center"},
                { text: "الصورة ", value: "image", width: "20%", align: "center" },
                {text: "تفعيل", value: "eye", width: "20%", align: "center"},
                {
                    text: "التحكم",
                    value: "control",
                    sortable: false,
                    width: "20%",
                    align: "center",
                },
            ],
        };
    },
};
</script>


<style>

</style>
