<template>
    <v-container fluid>
      <v-row class="mt-10">
        <v-col cols="12" lg="2">
          <v-card shaped>
            <v-card-title class="d-flex flex-column justify-center blue--text">
              <v-card-subtitle>
                <h2 class="mt-0 pt-0 blue--text">81</h2>
              </v-card-subtitle>

              <span>
                <v-icon color="blue">fa-sign-in-alt</v-icon>
                وصول اليوم
              </span>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" lg="2">
          <v-card shaped>
            <v-card-title class="d-flex flex-column justify-center blue--text">
              <v-card-subtitle>
                <h2 class="mt-0 pt-0 blue--text">1870</h2>
              </v-card-subtitle>

              <span>
                <v-icon color="blue">fa-sign-in-alt</v-icon>
                تسجيل الدخول
              </span>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" lg="2">
          <v-card shaped>
            <v-card-title class="d-flex flex-column justify-center primary--text">
              <v-card-subtitle>
                <h2 class="mt-0 pt-0 primary--text">1072</h2>
              </v-card-subtitle>

              <span>
                <v-icon color="primary">fa-sign-out-alt</v-icon>
                 7777
              </span>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12" lg="2">
          <v-card shaped>
            <v-card-title class="d-flex flex-column justify-center primary--text">
              <v-card-subtitle>
                <h2 class="mt-0 pt-0 primary--text">607</h2>
              </v-card-subtitle>

              <span>
                <v-icon color="primary">fa-sign-out-alt</v-icon>
                تسجيل الخروج
              </span>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12" lg="2">
          <v-card shaped>
            <v-card-title class="d-flex flex-column justify-center purple--text">
              <v-card-subtitle>
                <h2 class="mt-0 pt-0 purple--text">107</h2>
              </v-card-subtitle>

              <span>
                <v-icon color="purple">fa-users</v-icon>
                5555
              </span>
            </v-card-title>
          </v-card>
        </v-col>

        <v-col cols="12" lg="2">
          <v-card shaped>
            <v-card-title class="d-flex flex-column justify-center green--text">
              <v-card-subtitle>
                <h2 class="mt-0 pt-0 green--text">175</h2>
              </v-card-subtitle>

              <span>
                <v-icon color="green">fa-file</v-icon>
                 5555
              </span>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>

      <!-- <v-row class="my-10" align="center" justify="center">
        <v-col cols="12" md="6">
          <v-card
            width="100%"
            height="400px"
            class="d-flex justify-center align-center"
          >
            <v-card-title> قيد التطوير ... </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            width="100%"
            height="400px"
            class="d-flex justify-center align-center"
          >
            <v-card-title> قيد التطوير ... </v-card-title>
          </v-card>
        </v-col>
      </v-row> -->

    </v-container>
  </template>

  <script>
  import AppLayout from "@/Layouts/Layout";
  export default {
    layout: AppLayout,
    metaInfo() {
      return {
        title: this.pageTitle,
      };
    },

    data() {
      return {
        pageTitle: "لوحة التحكم",
      };
    },
  };
  </script>

  <style>
  </style>
