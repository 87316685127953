<template>
    <the-datatable
    title="الادوار"
    :headers="headers"
    :nametable="nametable"
    :dataSource="roles">
        <!-- <template v-slot:actions="{ link }">
            <v-menu
                v-if="can(['roles-delete','roles-update']) "
                offset-y
                origin="center center"
                :nudge-bottom="10"
                transition="scale-transition">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark v-bind="attrs" v-on="on">
                        <v-icon>fa-bars</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <inertia-link class=" v-list-item v-list-item--link theme--light" :href="`${link}/edit`"    v-if="can('roles-update') ">تعديل
                    </inertia-link>
                    <v-list-item link @click="open(link)" light    v-if="can('roles-delete') ">
                        <v-list-item-title>حذف</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template> -->
    </the-datatable>
</template>

<script>
import layout from "@/Layouts/Layout";

export default {
    layout: layout,
    components: {TheDatatable: () => import("@/components/admin/TheDatatable")},
    props: ["roles"],
    metaInfo() {
        return {
            title: "الادوار",
        }
    },


    methods: {
        open(data) {
            this.$root.$confirm.open('حذف', ' هل تريد حذف هذا الدور سيتم هذا هذا الدور من جميع المستخدمين المرتبطين به', {color: 'red'}).then(confirm => {
                if (confirm) {
                    this.$inertia.delete(data, {
                        preserveState: false
                    })

                }
            })
        }
    },
    data() {
        return {
            
      nametable: "roles",
            headers: [
                {
                    text: "ID",
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "20%",
                },
                {text: "الاسم المعروض", value: "display_name", width: "60%", align: "center"},
                {
                    text: "التحكم",
                    value: "control",
                    sortable: false,
                    width: "15%",
                    align: "center",
                },
            ],
        };
    },

}
</script>

<style>

</style>
