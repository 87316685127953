export const month_my = [
    {
        id: 1,
        name: "يناير ",
    },
    {
        id: 2,
        name: "فبراير",
    },
    {
        id: 3,
        name: "مارس",
    },
    {
        id: 4,
        name: "إبرير",
    },
    {
        id: 5,
        name: "مايو",
    },
    {
        id: 6,
        name: "يونيو",
    },
    {
        id: 7,
        name: "يوليو",
    },
    {
        id: 8,
        name: "أغسطس",
    },
    {
        id: 9,
        name: "سبتمبر",
    },
    {
        id: 10,
        name: "أكتوبر",
    },
    {
        id: 11,
        name: "نوفمبر",
    },
    {
        id: 12,
        name: "ديسنبر",
    },


]
export const month_hj = [
    {
        id: 1,
        name: "محرم ",
    },
    {
        id: 2,
        name: "صفر",
    },
    {
        id: 3,
        name: "ربيع الأول",
    },
    {
        id: 4,
        name: "ربيع الآخر",
    },
    {
        id: 5,
        name: "جماد الأولى",
    },
    {
        id: 6,
        name: "جماد الآخرة",
    },
    {
        id: 7,
        name: "رجب",
    },
    {
        id: 8,
        name: "شعبان",
    },
    {
        id: 9,
        name: "رمضان",
    },
    {
        id: 10,
        name: "شوال",
    },
    {
        id: 11,
        name: "ذوالقعدة",
    },
    {
        id: 12,
        name: "ذوالحجة",
    },


]
export const seasons = [
    {
        id: 1,
        name: "فصل الشتاء",
    },
    {
        id: 2,
        name: "فصل الربيع",
    },
    {
        id: 3,
        name: "فصل الصيف",
    },
    {
        id: 4,
        name: "فصل الخريف",
    },


]
export const yearms = [
    {
        id: 2023,
    },
    {
        id: 2024,
    },
    {
        id: 2025,
    },
    {
        id: 2026,
    },
    {
        id: 2027,
    },
    {
        id: 2028,
    },
    {
        id: 2029,
    },
    {
        id: 2030,
    },


]
export const yearhs = [
    {
        id: 1445,
    },
    {
        id: 1446,
    },
    {
        id: 1447,
    },
    {
        id: 1448,
    },
    {
        id: 1449,
    },
    {
        id: 14450,
    },
    {
        id: 14451,
    },
    {
        id: 14452,
    },


]
export const monthhims = [
    {
        id: 1,
        name: "ذو الصراب الأول ",
    },
    {
        id: 2,
        name: "ذو الخراف",
    },
    {
        id: 3,
        name: "ذو النابة",
    },
    {
        id: 4,
        name: "ذو علان",
    },
    {
        id: 5,
        name: "ذو مذران",
    },
    {
        id: 6,
        name: "ذو القياظ",
    },
    {
        id: 7,
        name: "ذو المبكر",
    },
    {
        id: 8,
        name: "ذو معون",
    },
    {
        id: 9,
        name: "ذو المهلة - الصراب الثاني",
    },
    {
        id: 10,
        name: "ذو الدباء",
    },
    {
        id: 11,
        name: "ذو الأول",
    },
    {
        id: 12,
        name: "ذو الحلة - الدثى",
    },


]
export const monthsuns = [
    {
        id: 1,
        name: "الحوت",
    },
    {
        id: 2,
        name: "النطح",
    },
    {
        id: 3,
        name: "سهيل",
    },
    {
        id: 4,
        name: "باعريق",
    },
    {
        id: 5,
        name: "السماك",
    },
    {
        id: 6,
        name: "خباء",
    },
    {
        id: 7,
        name: "الغفر",
    },
    {
        id: 8,
        name: "الفرغ",
    },
    {
        id: 9,
        name: "البلدة",
    },
    {
        id: 10,
        name: "المزرم",
    },
    {
        id: 11,
        name: "الدلو",
    },
    {
        id: 12,
        name: "القلب",
    },
    {
        id: 13,
        name: "الشول",
    },
    {
        id: 14,
        name: "النعايم",
    },
    {
        id: 15,
        name: "الإكليل",
    },
    {
        id: 16,
        name: "الصرفة",
    },
    {
        id: 17,
        name: "العواء",
    },
    {
        id: 18,
        name: "البطين",
    },
    {
        id: 19,
        name: "الثريا",
    },
    {
        id: 20,
        name: "الذراع",
    },
    {
        id: 21,
        name: "النثرة",
    },
    {
        id: 22,
        name: "الطرف",
    },
    {
        id: 22,
        name: "البركان",
    },
    {
        id: 23,
        name: "الهقعة",
    },
    {
        id: 24,
        name: "الزبان",
    },
    {
        id: 25,
        name: "الزبرة",
    },
    {
        id: 26,
        name: "الجبهة",
    },
    {
        id: 27,
        name: "الهنعة",
    },


]
export const monthzss = [
    {
        id: 1,
        name: "أول فارع",
    },
    {
        id: 2,
        name: "الروابع الأولى",
    },
    {
        id: 3,
        name: "الروابع الثانية",
    },
    {
        id: 4,
        name: "السماك الفرع",
    },
    {
        id: 5,
        name: "خامس علان",
    },
    {
        id: 6,
        name: "خريف العلب",
    },
    {
        id: 7,
        name: "سابع علان",
    },
    {
        id: 8,
        name: "سادس علان",
    },
    {
        id: 9,
        name: "سبت السبوت",
    },
    {
        id: 10,
        name: "سهيل",
    },
    {
        id: 11,
        name: "طلوع الثور",
    },
    {
        id: 12,
        name: "طلوع الصلم أو الظلم الثاني",
    },
    {
        id: 13,
        name: "طلوع النجمين أو الظلم الأول",
    },
    {
        id: 14,
        name: "طلوع كامه",
    },
    {
        id: 15,
        name: "ظافر أول",
    },
    {
        id: 16,
        name: "ظافر ثاني",
    },
    {
        id: 17,
        name: "عشاء الثور",
    },
    {
        id: 18,
        name: "عشاء الروابع الأولى",
    },
    {
        id: 19,
        name: "عشاء الروابع الثانية",
    },
    {
        id: 20,
        name: "عشاء الصلم أو الظلم الثاني",
    },
    {
        id: 21,
        name: "عشاء العلب",
    },
    {
        id: 22,
        name: "عشاء النجمين أو الظلم الأول",
    },
    {
        id: 23,
        name: "عشاء خامس الصواب",
    },
    {
        id: 24,
        name: "عشاء ربيع كامه",
    },
    {
        id: 25,
        name: "عشاء سابع الصواب",
    },
    {
        id: 26,
        name: "عشاء سادس الصواب",
    },
    {
        id: 27,
        name: "عشاء سهيل",
    },
    {
        id: 28,
        name: "غروب الثور",
    },
    {
        id: 29,
        name: "غروب كامه",
    },
    {
        id: 30,
        name: "ديسنبر",
    },


]
export const monthzhs = [
    {
        id: 1,
        name: "الغفر ",
    },
    {
        id: 2,
        name: "الزبانه",
    },
    {
        id: 3,
        name: "الجبهة",
    },
    {
        id: 4,
        name: "الزبرة",
    },
    {
        id: 5,
        name: "الصرفة",
    },
    {
        id: 6,
        name: "الشرطين",
    },
    {
        id: 7,
        name: "البطين",
    },
    {
        id: 8,
        name: "العواء",
    },
    {
        id: 9,
        name: "النثرة",
    },
    {
        id: 10,
        name: "الطرف",
    },
    {
        id: 11,
        name: "السماك",
    },
    {
        id: 12,
        name: "الهقعة",
    },
    {
        id: 13,
        name: "الهنعة",
    },
    {
        id: 14,
        name: "الذراع",
    },
    {
        id: 15,
        name: "الدبران",
    },
    {
        id: 16,
        name: "المؤخر",
    },
    {
        id: 17,
        name: "بطن الحوت",
    },
    {
        id: 18,
        name: "الإكليل",
    },
    {
        id: 19,
        name: "القلب",
    },
    {
        id: 20,
        name: "سعد الذابح",
    },

    {
        id: 21,
        name: "سعد بلع",
    },

    {
        id: 22,
        name: "سعد السعود",
    },

    {
        id: 23,
        name: "الشولة",
    },
    {
        id: 24,
        name: "النعائم",
    },
    {
        id: 25,
        name: "البلدة",
    },
    {
        id: 26,
        name: "سعد الأخبية",
    },
    {
        id: 27,
        name: "المقدم",
    },
    {
        id: 28,
        name: "الثريا",
    },
]
export const monthromy = [
     {
         id: 1,
         name: "كانون ثاني",
     },
     {
         id: 2,
         name: "شباط",
     },
     {
         id: 3,
         name: "آذار",
     },
     {
         id: 4,
         name: "نيسان",
     },
     {
         id: 5,
         name: "آيار",
     },
     {
         id: 6,
         name: "حزيران",
     },
     {
         id: 7,
         name: "تموز",
     },
     {
         id: 8,
         name: "آب",
     },
     {
         id: 9,
         name: "آيلول",
     },
     {
         id: 10,
         name: "تشرين الأول",
     },
     {
         id: 11,
         name: "تشرين ثاني",
     },
     {
         id: 12,
         name: "كانون أول",
     },
 ]
 export const monthgriny = [
     {
         id: 1,
         name: "قران (13)",
     },
     {
         id: 2,
         name: "قران (11)",
     },
     {
         id: 3,
         name: "قران التسع",
     },
     {
         id: 4,
         name: "قران السبع",
     },
     {
         id: 5,
         name: "قران الخمس",
     },
     {
         id: 6,
         name: "قران الثلاث",
     },
     {
         id: 7,
         name: "قران (25)",
     },
     {
         id: 8,
         name: "قران (23)",
     },
     {
         id: 9,
         name: "قران (21)",
     },
     {
         id: 10,
         name: "قران (19)",
     },
     {
         id: 11,
         name: "قران (17)",
     },
     {
         id: 12,
         name: "قران (15)",
     },



 ]

 export const monthc = [
     {
         id: 1,
     },
     {
         id: 2,
     },
     {
         id: 3,
     },
     {
         id: 4,
     },
     {
         id: 5,
     },
     {
         id: 6,
     },
     {
         id: 7,
     },
     {
         id: 8,
     },
     {
         id: 9,
     },
     {
         id: 10,
     },
     {
         id: 11,
     },
     {
         id: 12,
     },
     {
         id: 13,
     },
     {
         id: 14,
     },
     {
         id: 15,
     },
     {
         id: 16,
     },
     {
         id: 17,
     },
     {
         id: 18,
     },
     {
         id: 19,
     },
     {
         id: 20,
     },
     {
         id: 21,
     },
     {
         id: 22,
     },
     {
         id: 23,
     },
     {
         id: 24,
     },
     {
         id: 25,
     },
     {
         id: 26,
     },
     {
         id: 27,
     },
     {
         id: 28,
     },
     {
         id: 29,
     },
     {
         id: 30,
     },
     {
         id: 31,
     },
     {
         id: 32,
     },
     {
         id: 33,
     },



 ]
