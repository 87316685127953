<template>
<div>
welecom</div>
</template>
<script>
 
 import AppLayout from '@/Layouts/Layout'
  export default {
      layout:AppLayout
  }
</script>