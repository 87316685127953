<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2>الاصناف</h2>
      </v-col>
      <v-col cols="12" sm="12">
        <form
          @submit.prevent="submit"
          enctype="multipart/form-data"
          method="POST"
        >
          <v-card elevation="3">
            <v-col cols="12">
              <h2 class="text-center">إضافة صنف جديد</h2> </v-col
            ><v-card-text>
                <v-row>
                <v-col cols="6" sm="6" md="6">
                  <c-text-field
                    label="اسم الصنف "
                    name="name"
                    v-model="form.name"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <c-text-field
                    label="اسم الصنف بالغة الانجليزية"
                    name="name_en"
                    v-model="form.name_en"
                  />
                </v-col>
              </v-row>

                         <!-- <v-row>
                                <v-col cols="12" sm="12" md="12">
                                   <v-file-input
                                        counter
                                        show-size
                                        label="اضافة صورة "
                                        outlined
                                        dense
                                        truncate-length="15"
                                        name="image"
                                        @change="onChangeimage"
                                    ></v-file-input>
                                </v-col>
                            </v-row> -->

              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <input type="file" name="image" @change="onChangeimage" />
                </v-col>

              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <InertiaLinkBtn
                                :url="route('boycott_categories.index')"
                                class="v-btn v-size--default primary theme--light"
                                title="الرجوع للخلف"
                            />
              <v-btn type="submit" :loading="loading" color="primary"
                >حفظ</v-btn
              >
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";

import cTextField from "@/components/shared/cTextField";
// import Input from "../../../Jetstream/Input.vue";

export default {
  layout: layout,
  cTextField: cTextField,

  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
  },
  data() {
    return {
      loading: false,
      form: {
        name: "",
        name_en: "",
        image: "",
      },
    };
  },

  metaInfo() {
    return {
      title: "اضافة صنف ",
    };
  },
  methods: {
    // onChangeimage(PointerEvent) {
    //   console.log(PointerEvent)
    //   //  this.file = e.target.files[0];
    // },

    //       submit() {
    //       if (this.$refs.image) {
    //           this.form.image = this.$refs.image.files[0]
    //       }
    //       this.form.post(route('boycott_categories.store'),this.form, {
    //           errorBag: 'updateProfileInformation',
    //           preserveScroll: true
    //       });
    //   },
    //  selectNewimage() {
    //       this.$refs.image.click();
    //   },
    //   updateimagePreview() {
    //       const reader = new FileReader();
    //       reader.onload = (e) => {
    //           this.imagePreview = e.target.result;
    //       };
    //       reader.readAsDataURL(this.$refs.image.files[0]);
    //   },

    // onPickFile() {
    //   this.$refs.fileInput.click()
    //   },
    // submit(event) {

    //   const files = event.target.files
    //   let filename = files[0].name
    //   const fileReader = new FileReader()
    //   fileReader.addEventListener('load', () => {
    //     this.imageUrl = fileReader.result
    //   })
    //   fileReader.readAsDataURL(files[0])
    //   this.form.image = files[0]

    //       const config = {headers:{'content-type': 'multipart/form-data',}};
    //         this.$inertia.post(route("boycott_categories.store",this.form,config),{
    //         onProgress: (progress) => {this.loading = true;},
    //         onSuccess: (page) => {this.loading = false;},
    //         onFinish: () => {this.loading = false;},});

    // },

    onChangeimage(event) {
      const image = event.target.files[0];
      this.form.image = image;
      // this.form.category_id = category_id;
    },
    submit() {
      let config = { headers: { "content-type": "multipart/form-data" } };
      this.$inertia.post(route("boycott_categories.store"), this.form, config, {
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
};
</script>

<style>
</style>
