export default {
    // mounted(){
    //     console.log("mounted")
    // },
    methods: {
        can (permission ) {
            if(typeof permission =="string")  permission=[permission];
         let user_permissions= this.$page.props.user_permissions;
        //  let user_permissions= this.$page.props.user.userPermissions;
       return   permission.some(r =>user_permissions.includes(r))
        },
    },
};
