<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2>  المعالم الزراعية</h2>
      </v-col>
      <v-col cols="12" sm="12">
        <form @submit.prevent="submit" enctype="multipart/form-data" method="POST" >
          <v-card elevation="3">
             <v-col cols="12">
        <h2 class="text-center">تعديل   على  المعلز الزراعي  </h2>
      </v-col><v-card-text>

                            <v-row>
                                <v-col  cols="3" sm="3" md="3">
                                <v-select
                                    :items="month_my"
                                    item-value="id"
                                    item-text="name"
                                    name="starting_month"
                                   v-model="form.starting_month"
                                    outlined
                                    dense
                                    clearable
                                    label="شهر البداية"/>
                                </v-col>
                                <v-col  cols="3" sm="3" md="3">
                                <v-select
                                    :items="monthc"
                                    item-value="id"
                                    item-text="id"
                                     label= "اليوم البداية"
                                    name="starting_day"
                                    v-model="form.starting_day"
                                    outlined
                                    dense
                                    clearable />
                                </v-col>

                                <v-col  cols="3" sm="3" md="3">
                                <v-select
                                    :items="month_my"
                                    item-value="id"
                                    item-text="name"
                                    name="end_month"
                                   v-model="form.end_month"
                                    outlined
                                    dense
                                    clearable
                                    label="شهر النهاية"/>
                                </v-col>
                                <v-col  cols="3" sm="3" md="3">
                                <v-select
                                    :items="monthc"
                                    item-value="id"
                                    item-text="id"
                                     label= "اليوم النهاية"
                                    name="end_day"
                                    v-model="form.end_day"
                                    outlined
                                    dense
                                    clearable />
                                </v-col>
                            </v-row>

                          <v-row>
                              <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="اسم   المعلم "
                                        filled
                                        name="name"
                                        v-model="form.name"
                                        auto-grow
                                        rows="2"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="وصف المعلم الزراعي"
                                        filled
                                        name="description"
                                        v-model="form.description"
                                        auto-grow
                                        rows="2"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                          </v-row>

                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="عنوان  المعلم "
                                        filled
                                        name="title"
                                        v-model="form.title"
                                        auto-grow
                                        rows="2"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="تفاصيل  المعلم "
                                        filled
                                        name="details"
                                        v-model="form.details"
                                        auto-grow
                                        rows="2"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                          </v-row>

            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <InertiaLinkBtn
                :url="route('agricultural_calendars.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"/>
              <v-btn type="submit" :loading="loading" color="primary">حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";

import {month_my, monthc } from "@/data/temp.js";
export default {
  layout: layout,
  props: ["agricultural_calendars"],
  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
  },
  metaInfo() {
    return {
      title: "تعديل على التقويم الهجري ",
    };
  },
  methods: {
    submit() {
     //  let config = {headers:{'content-type': 'multipart/form-data',}};
      this.$inertia.put(route("agricultural_calendars.update", this.form.id), this.form, {
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
  data() {
    return {
      loading: false,
      tab: null,
      month_my,
            monthc,
      form:this.agricultural_calendars,
    };
  },
};
</script>
<style>
</style>
