<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center" v-if="title">
        <h1>{{ title }}</h1>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="بحث"
          v-model="search"
          outlined
          v-if="searchfilter"
        ></v-text-field>
        <InertiaLinkBtn
          v-if="create"
          class="v-btn v-size--default primary"
          title=" إضافة جديد"
          :url="`${dataSource.path}/create`"
        />
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="mydata"
          :loading="loading"
          :options.sync="options"
          hide-default-footer
          class="elevation-5"
          loading-text="تحميل"
          no-data-text="لاتوجد بيانات"
        >
    <template #item.status="{ item }">
            <slot name="status" v-bind:data="item" />
          </template>

          <template #item.image="{ item }">

         <v-img lazy-src="https://picsum.photos/id/11/10/6"
          max-height="70"  max-width="70" :src="item.image" ></v-img>
          </template>

          <template #item.eye="{ item }">
            <v-btn x-small text v-if="can(`${nametable}`+'-eye') "  @click="open_eye(item.id)">
              <v-icon small v-if="item.active == 1" color="#087ff1"
                >fa-eye</v-icon
              >
              <v-icon small v-else color="red">fa-eye-slash</v-icon>
            </v-btn>
          </template>

          <template #item.control="{ item }">
            <slot
              name="control"
              v-bind:link="`${dataSource.path}/${item.id}`"
              v-bind:id="item.id"
              v-bind:item="item"
            />
  <inertia-link x-small text   v-if="can(`${nametable}`+'-update') "
    class="v-btn v-btn--flat v-btn--text"
  :href="`${dataSource.path}/${item.id}/edit`">
    <slot>
   <v-icon small>fa-pen</v-icon>
    </slot>
  </inertia-link>

 <!-- <v-btn x-small text  v-if="can(`${nametable}`+'-update') "   @click="update(item.id)">
             <v-icon small>fa-pen</v-icon>
            </v-btn>-->

            <v-btn x-small text  v-if="can(`${nametable}`+'-delete') "   @click="deletet(item.id,item.name)">
              <v-icon small>fa-times</v-icon>
            </v-btn>
          </template>


          <template v-slot:footer>
            <!-- <Pagination
          :last_page="lastPage"
          :current_page="currentPage"
          @change="updatePagination"
        /> -->
            <v-divider class="mt-3"></v-divider>
            <div class="d-flex flex-wrap my-2 py-2">
              <div class="flex-grow-0 px-5 py-2">{{ rowdata }}</div>
              <v-pagination
                class="flex-grow-1"
                v-if="total > 0"
                v-model="currentPage"
                :length="lastPage"
                :total-visible="7"
                @input="updatePagination"
              >
              </v-pagination>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import Pagination from "@/components/admin/base/Pagination";
import InertiaLinkBtn from "@/components/InertiaLinkBtn";
import _ from "lodash";
import Vue from "vue";
import NProgress from "nprogress";
export default {
  components: {
    //   Pagination ,
    Vue,
    InertiaLinkBtn,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    dataSource: {
      type: Object,
      required: true,
    },
        nametable: {
      type: String,
    },

    title: {
      type: String,
    },
    create: {
      // type:
      type: Boolean,
      default: true,
    },
    searchfilter: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.$nextTick(() => (this.loadData = true));
  },
  computed: {
    rowdata() {
      return (
        Math.min(this.currentPage * this.per_page, this.total) +
        " / " +
        this.total
      );
    },
  },
  data() {
    return {
      search: "",
      loadData: false,
      loading: false,
      total: this.dataSource.total,
      lastPage: this.dataSource.last_page,
      currentPage: this.dataSource.current_page,
      per_page: this.dataSource.per_page,
      options: {},
      mydata: this.dataSource.data,
    };
  },
  watch: {

    search: _.debounce(function () {
      this.updatePagination(1);
    }, 1000),
    options: {
      handler() {
        if (this.loadData) {
          this.updatePagination(1);
        }
      },
      deep: true,
    },
  },
  methods: {
   open_eye(id) {
      axios.get(route(this.nametable + ".eye", id)).then((res) => {
        if (res.status == 200) {
          if (res.data && res.data.eye) {
            Vue.set(
              this.mydata,
              this.mydata.findIndex((f) => f.id == id),
              res.data.eye
            );
          }
        }
      });
    },
// update(id){
//   this.$root.$confirm
//         .open("حذف","هل تريد حذف هذا "+name, { color: "red" })
//         .then((confirm) => {
//           if (confirm) {
//             axios.get(route(this.nametable + ".update", id)).then((res) => {
//               if (res.status == 200) {
//                 this.updatePagination(this.currentPage); //  this.mydata = this.mydata.splice(index, 1); // res.data.form;
//               }
//             });
//           }
//         });
// },
    deletet(id, name) {
      this.$root.$confirm
        .open("حذف","هل تريد حذف هذا "+name, { color: "red" })
        .then((confirm) => {
          if (confirm) {
            axios.get(route(this.nametable + ".destroy", id)).then((res) => {
              if (res.status == 200) {
                this.updatePagination(this.currentPage); //  this.mydata = this.mydata.splice(index, 1); // res.data.form;
              }
            });
          }
        });
    },
    updatePagination(value) {
      this.currentPage = value;
      this.getData(value);
    },
    getData(page = 1) {
      NProgress.configure({ showSpinner: false }).start();
      this.loading = true;
      let { sortBy, sortDesc } = this.options;
      axios
        .get(this.dataSource.path, {
          params: {
            search: this.search,
            sortBy: sortBy[0],
            sortDesc:
              sortDesc[0] == undefined
                ? "desc"
                : sortDesc[0] == false
                ? "asc"
                : "desc",
            page,
          },
        })
        .then((res) => {
          NProgress.done();
          this.loading = false;
          this.mydata = res.data.data;
          this.total = res.data.total;
          this.lastPage = res.data.last_page;
        });
    },
  },
};
</script>

<style>
</style>
