<template>
  <the-datatable
    title="الشركات المقاطعة "
    :headers="headers"
    :nametable="nametable"
    :dataSource="boycott_companies">
  </the-datatable>
</template>

<script>
import layout from "@/Layouts/Layout";
// import TheDatatable from "@/components/admin/TheDatatable";
export default {
  layout: layout,
//   TheDatatable: TheDatatable,
  components: { TheDatatable: () => import("@/components/admin/TheDatatable") },
  props: ["boycott_companies"],
  metaInfo() {
    return {
      title: "الشركات المقاطعة ",
    };
  },

  methods: {
    open(data) {
      this.$root.$confirm
        .open("حذف", "هل تريد حذف هذا الصنف ", { color: "red" })
        .then((confirm) => {
          if (confirm) {
            this.$inertia.delete(data, {
              preserveState: false,
            });
          }
        });
    },
  },
  data() {
    return {
      nametable: "boycott_companies",
      headers: [
        {
          text: "الرقم ",
          align: "start",
          sortable: false,
          value: "id",
          width: "10%",
        },
        // { text: "الترتيب", value: "id", width: "5%", align: "center" },
        { text: "الاسم ", value: "name", width: "20%", align: "center" },
        { text: "الصورة ", value: "image", width: "30%", align: "center" },
        { text: "تفعيل", value: "eye", width: "10%", align: "center" },
        {
          text: "التحكم",
          value: "control",
          sortable: false,
          width: "20%",
          align: "center",
        },
      ],
    };
  },
};
</script>


<style>
</style>
