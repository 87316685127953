<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2 class="text-center">إضافة مستخدم</h2>
      </v-col>
      <v-col cols="12" sm="9">
        <form @submit.prevent="submit">
          <v-card elevation="3">
            <v-card-text>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <c-text-field
                    label="اسم المستخدم"
                    outlined
                    type="text"
                    name="name"
                    v-model="form.name"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <c-text-field
                    label="رقم الهاتف "
                    outlined
                    type="number"
                    name="phone"
                    v-model="form.phone"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <c-text-field
                    label="البريد الالكتروني"
                    outlined
                    name="email"
                    type="email"
                    v-model="form.email"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <c-password
                    v-model="form.password"
                    name="password"
                    dense
                    label="كلمة المرور"
                    :class="{ 'is-invalid': errorFor('password') }"
                    :error="!!errorFor('password')"
                    :error-messages="errorFor('password')"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <c-password
                    v-model="form.password_confirmation"
                    name="password_confirmation"
                    dense
                    label=" تاكيد كلمة المرور "
                    :class="{ 'is-invalid': errorFor('password_confirmation') }"
                    :error="!!errorFor('password_confirmation')"
                    :error-messages="errorFor('password_confirmation')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-select
                    v-if="can('roles-create')"
                    clearable
                    v-model="form.role"
                    :items="roles"
                    dense
                    outlined
                    item-text="display_name"
                    item-value="id"
                    :class="{ 'is-invalid': errorFor('role') }"
                    :error="!!errorFor('role')"
                    :error-messages="errorFor('role')"
                    label="الدور "
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <div v-if="form.role == 3">
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      label="صفتك  "
                      outlined
                      type="text"
                      name="name_companies"
                      v-model="form.name_companies"
                    />
                  </v-col>
                   <v-col cols="6" sm="6" md="6">
                    <v-text-field
                      label=" العنوان  "
                      outlined
                      type="text"
                      name="addresse"
                      v-model="form.addresse"
                    />
                  </v-col>
                </v-row>
                <v-row>
                 <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label="رقم هاتف"
                      outlined
                      type="number"
                      name="mobile"
                      v-model="form.mobile"
                    />
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label=" رقم عاتف اخر"
                      outlined
                      type="number"
                      name="mobile2"
                      v-model="form.mobile2"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="ملاحظة  "
                      auto-grow
                      outlined
                      rows="6"
                      name="details"
                      row-height="15"
                      v-model="form.details"
                    >
                      {{ form.details }}
                    </v-textarea>
                  </v-col>
                </v-row>
              </div>

              <div v-if="form.role == 4">
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label="الاسم الاول  "
                      outlined
                      type="text"
                      name="fname"
                      v-model="form.fname"
                    />
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label="الاسم الاخير  "
                      outlined
                      type="text"
                      name="lname"
                      v-model="form.lname"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label=" العنوان   "
                      outlined
                      type="text"
                      name="addresse"
                      v-model="form.addresse"
                    />
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label="هاتف اخر"
                      outlined
                      type="number"
                      name="mobile"
                      v-model="form.lname"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="ملاحظة  "
                      auto-grow
                      outlined
                      rows="6"
                      name="details"
                      row-height="15"
                      v-model="form.details"
                    >
                      {{ form.details }}
                    </v-textarea>
                  </v-col>
                </v-row>

              </div>
              <template v-if="permissions">
                <v-divider></v-divider>
                <Permissions
                  :permissions="permissions"
                  :selected_permissions.sync="form.selected_permission"
                />
              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <InertiaLinkBtn
                :url="route('users.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"
              />
              <v-btn type="submit" :loading="loading" color="primary"
                >حفظ</v-btn
              >
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";
// import Password from "@/components/Password";

import cPassword from "@/components/shared/cPassword";
// import InertiaLinkBtn from "@/components/InertiaLinkBtn";
import Permissions from "@/components/admin/Permissions";

// Vue.component("cPassword", () =>
//     import ("@/components/shared/cPassword"))

export default {
  layout: layout,
  props: ["roles", "permissions"],
  components: {
    Password: () => import("@/components/Password"),
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
//     Permissions: () => import("@/components/admin/Permissions"),
  },
  metaInfo() {
    return {
      title: "اضافة مستخدم",
    };
  },
  methods: {
    submit() {
      this.$inertia.post(route("users.store"), this.form, {
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
  data() {
    return {
      loading: false,
      tab: null,
      showPassword: false,
      showPassword_confirm: false,
      form: {
        name: "",
        email: "",
        phone: "",
        role: "",
        details: "",
        fname: "",
        lnane: "",
        mobile: "",
        mobile2: "",
        addresse: "",
        name_companies: "",
        selected_permission: [],
        password: "",
        password_confirmation: "",
      },
    };
  },
};
</script>

<style>
</style>
