<template>
    <the-datatable
    title="التقويم الهجري  "
    :headers="headers"
      :nametable="nametable"
       :dataSource="calendar_tepyans">



    </the-datatable>
</template>

<script>
import layout from "@/Layouts/Layout";

// import TheDatatable from "@/components/admin/TheDatatable";
export default {
    layout: layout,
    components: {TheDatatable: () => import("@/components/admin/TheDatatable")},
    props: ["calendar_tepyans"],
    metaInfo() {
        return {
            title: "التقويم الهجري",
        }
    },

    methods: {

    },
    data() {
        return {



    // boycott_companies_id
    // boycott_categories_id
    // name
    // details
    // reason_boycott
    // name_en
    // details_en
    // reason_boycott_en
    // qr_code
    // pr_code
    // image
      nametable: "calendar_tepyans",
            headers: [
                {
                    text: "الرقم ",
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "5%",
                },

                    // daym
    // monthm
    // yearm
    // season
    // dayhim
    // monthhim
    // daysun
    // monthsun
    // dayzs
    // monthzs
    // dayzh
    // monthzh
    // day_of_week

                {text: " م م", value: "daym", width: "5%", align: "center"},
                {text: "ش م", value: "monthm", width: "5%", align: "center"},
                {text: "س م", value: "yearm", width: "5%", align: "center"},
                {text: "ي ح", value: "dayhim", width: "5%", align: "center"},
                {text: "ش ح", value: "monthhim", width: "5%", align: "center"},
                {text: "م ش", value: "daysun", width: "5%", align: "center"},
                {text: "ش ش", value: "monthsun", width: "5%", align: "center"},
                {text: "م ز ص", value: "dayzs", width: "5%", align: "center"},
                {text: "ش ز ص", value: "monthzs", width: "5%", align: "center"},
                {text: "م ز ح", value: "dayzh", width: "5%", align: "center"},
                {text: "ش ز ح", value: "monthzh", width: "5%", align: "center"},
                {text: "تفعيل", value: "eye", width: "5%", align: "center"},
                {
                    text: "التحكم",
                    value: "control",
                    sortable: false,
                    width: "10%",
                    align: "center",
                },
            ],
        };
    },
};
</script>


<style>

</style>
