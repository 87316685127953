<template>
    <the-datatable
    title="السليدرات  "
    :headers="headers"
      :nametable="nametable"
       :dataSource="sliders">



    </the-datatable>
</template>

<script>
import layout from "@/Layouts/Layout";

// import TheDatatable from "@/components/admin/TheDatatable";
export default {
    layout: layout,
    components: {TheDatatable: () => import("@/components/admin/TheDatatable")},
    props: ["sliders"],
    metaInfo() {
        return {
            title: "الاصناف",
        }
    },

    methods: {

    },
    data() {
        return {

      nametable: "sliders",
            headers: [
                {
                    text: "الرقم ",
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "5%",
                },
                     //     title
//     title_en
//     url
//     details
//     details_en
//     date_start
//     date_end
//     time_start
//     time_end

                {text: "الاسم ", value: "title", width: "20%", align: "center"},
                {text: "العنوان", value: "details", width: "20%", align: "center"},
                { text: "تاريخ البداية ", value: "date_start", width: "10%", align: "center" },
                { text: "تاريخ النهاية ", value: "date_end", width: "10%", align: "center" },
                { text: "وقت البداية ", value: "time_start", width: "10%", align: "center" },
                { text: "وقت النهاية ", value: "time_end", width: "10%", align: "center" },
                {text: "تفعيل", value: "eye", width: "5%", align: "center"},
                {
                    text: "التحكم",
                    value: "control",
                    sortable: false,
                    width: "20%",
                    align: "center",
                },
            ],
        };
    },
};
</script>


<style>

</style>
