<template>
  <div>
    <v-app-bar color="primary" app dark flat>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <v-icon>fa-bars </v-icon>
      </v-app-bar-nav-icon>
      <v-spacer />

     <UserMenu />
    </v-app-bar>

    <the-admin-side-bar
      v-model="drawer"
      @close-drawer="closeDrawer"
    ></the-admin-side-bar>
  </div>
</template>

<script>
import UserMenu from "@/components/UserMenu";
import TheAdminSideBar from "@/components/admin/navigation/TheAdminSideBar";
import InertiaLinkBtn from "@/components/InertiaLinkBtn";

import { Inertia } from "@inertiajs/inertia";
export default {
  components: {
      UserMenu,
    TheAdminSideBar,
    InertiaLinkBtn,
  },
  data() {
    return {
      drawer: null,

    };
  },
  methods: {
    closeDrawer() {
      Inertia.on("finish", () => {
        this.drawer = false;
      });
    },
  },

};
</script>

<style>
.w-100 {
  width: 100%;
}
</style>
