<template>
  <v-container fluid>


  </v-container>
</template>

<script>

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDYCWmbebPYhTb0b7YVyjjwvBPVMKpNGXM",
  authDomain: "tapyen-73fe1.firebaseapp.com",
  projectId: "tapyen-73fe1",
  storageBucket: "tapyen-73fe1.appspot.com",
  messagingSenderId: "256605254139",
  appId: "1:256605254139:web:597a1604775228d60d1b5e",
  measurementId: "G-VT4NF4Q7R8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

import AppLayout from "@/Layouts/Layout";
export default {
  layout: AppLayout,
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },

  data() {
    return {
      pageTitle: "لوحة التحكم",
    };
  },
};
</script>

<style>
</style>
