<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12">
                <h2>الاصناف</h2>
            </v-col>
            <v-col cols="12" sm="12">
                <form @submit.prevent="submit" enctype="multipart/form-data">
                    <v-card elevation="3">
                        <v-col cols="12">
                            <h2 class="text-center">
                                التعديل على صنف  : {{ boycott_categories.name }}
                            </h2> </v-col
                        ><v-card-text>
                            <v-row>
                <v-col cols="6" sm="6" md="6">
                  <c-text-field
                    label="اسم الصنف "
                    name="name"
                    v-model="form.name"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <c-text-field
                    label=" اسم الصنف بالغة الانجليزية "
                    name="name_en"
                    v-model="form.name_en"
                  />
                </v-col>
              </v-row>
              <v-row >
             <v-col cols="12" sm="12" md="12">
                         <input
                        type="file"
                        name="image"
                        @change="onChangeimage"
                        />
                        </v-col>
            </v-row>
                            <!-- <v-row>
                                <v-col cols="8" sm="8" md="8">

                                   <v-file-input
                                        counter
                                        show-size
                                        label="اضافة صورة "
                                        outlined
                                        dense
                                        truncate-length="15"
                                        name="image"
                                        @change="onChangeimage"
                                    ></v-file-input>

                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <v-img
                                        contain
                                         v-model="form.image"
                                        lazy-src=""
                                        max-height="55"
                                        max-width="98"
                                        :src="form.image"
                                    ></v-img>
                                </v-col>
                            </v-row> -->
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <InertiaLinkBtn
                                :url="route('boycott_categories.index')"
                                class="v-btn v-size--default primary theme--light"
                                title="الرجوع للخلف"
                            />
                            <v-btn
                                type="submit"
                                :loading="loading"
                                color="primary"
                                >حفظ</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </form>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";
export default {
    layout: layout,
    props: ["boycott_categories"],
    components: {
        InertiaLinkBtn: () => import("@/components/InertiaLinkBtn")
    },
    metaInfo() {
        return {
            title: "تعديل الصنف "
        };
    },

    methods: {
     //    onChangeimage(event) {
     //        const image = event.target.files[0];
     //        // if (this.images == this.form.image) this.form.image = null;
     //        // else
     //         this.form.image = image;
     //    },
       onChangeimage(event){
         console.log(event.target.files[0])
        const image = event.target.files[0];
         this.form.image = image;
   },
        submit() {
            //let formData = new FormData();
            // formData.append('name',this.form.name );
            // formData.append('name_en',this.form.name_en );
            // formData.append('image', this.form.image );
          let config = { headers: { "content-type": "multipart/form-data" }};
          this.$inertia.post(route("boycott_categories.update2"),this.form,config,{
                    onProgress: progress => {
                        this.loading = true;
                    },
                    onSuccess: page => {
                        this.loading = false;
                    },
                    onFinish: () => {
                        this.loading = false;
                    }
                }
            );
        }
    },
    data() {
        return {
            loading: false,
                tab: null,
            // form: this.boycott_categories,
            // images: this.boycott_categories.image
            form: {
                id: this.boycott_categories.id || "",
                name: this.boycott_categories.name || "",
                name_en: this.boycott_categories.name_en || "",
                image: this.boycott_categories.image || "",
            }
        };
    }
};
</script>
<style></style>
