<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12">
                <h2>اضافة  عبارة </h2>
            </v-col>
            <v-col cols="12" sm="12">
                <form
                    @submit.prevent="submit"
                    enctype="multipart/form-data"
                    method="POST">
                    <v-card elevation="3">
                        <v-col cols="12">
                            <h2 class="text-center">إضافة  عبارة</h2>
                        </v-col>
                        <v-card-text>

                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-text-field
                                        label="عنوان العبارة"
                                        name="title"
                                        v-model="form.title"
                                        clearable
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-text-field
                                        label="عنوان العبارة بالغة الانجليزية"
                                        name="title_en"
                                        v-model="form.title_en"
                                        clearable
                                    />
                                </v-col>
                            </v-row>

                            <v-row>

                                <v-col cols="2" sm="2" md="2">
                                <v-checkbox
                                    label="عبارة امامية"
                                    name="show_before"
                                    v-model="form.show_before"
                                    class="py-3 my-0"/>
                                </v-col>

                                <v-col cols="2" sm="2" md="2">
                                <v-checkbox
                                    label="العرض هجري"
                                    name="show_hijris"
                                    v-model="form.show_hijris"
                                    class="py-3 my-0"/>
                                </v-col>

                                <v-col v-if="form.show_hijris  == false" cols="2" sm="2" md="2">
                                <v-select
                                    :items="month_my"
                                    item-value="id"
                                    item-text="name"
                                    name="month"
                                   v-model="form.month"
                                    outlined
                                    dense
                                    clearable
                                    label=" الشهر الميلادي "/>
                                </v-col>

                                <v-col v-else cols="2" sm="2" md="2">
                                <v-select
                                    :items="month_hj"
                                    item-value="id"
                                    item-text="name"
                                    name="month"
                                   v-model="form.month"
                                    outlined
                                    dense
                                    clearable
                                    label=" الشهر الهجري "/>
                                </v-col>

                                <v-col  cols="2" sm="2" md="2">
                                <v-select
                                    :items="[
                                    1,
                                    2,
                                    3,
                                    4,
                                    5,
                                    6,
                                    7,
                                    8,
                                    9,
                                    10,
                                    11,
                                    12,
                                    13,
                                    14,
                                    15,
                                    16,
                                    17,
                                    18,
                                    19,
                                    20,
                                    21,
                                    22,
                                    23,
                                    24,
                                    25,
                                    26,
                                    27,
                                    28,
                                    29,
                                    30,
                                    31,
                                    ]"

                                     label= "اليوم"
                                    name="day"
                                    v-model="form.day"
                                    outlined
                                    dense
                                    clearable />
                                </v-col>


                                <v-col cols="2" sm="2" md="2">
                            <v-checkbox
                                label="تنبية العبارة"
                                name="show_alert"
                                v-model="form.show_alert"
                                class="py-3 my-0"/>
                            </v-col>
                            <v-col v-if="form.show_alert  == true" cols="2" sm="2" md="2">
                                    <c-text-field
                                        label="الوقت  "
                                        type="time"
                                        name="show_alert_time_in"
                                        v-model="form.show_alert_time_in"
                                        clearable
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col  v-if="form.show_before  == false" cols="6" sm="6" md="6">
                                    <c-text-field
                                        label=" التخرجة او القائل"
                                        name="said"
                                        v-model="form.said"
                                        clearable
                                    />
                                </v-col>
                                <v-col  v-if="form.show_before  == false" cols="6" sm="6" md="6">
                                    <c-text-field
                                        label="التخرجة او القائل  بالغة الانجليزية"
                                        name="said_en"
                                        v-model="form.said_en"
                                        clearable
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="التفاصيل"
                                        filled
                                        name="details"
                                        v-model="form.details"
                                        auto-grow
                                        rows="4"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="التفاصيل  بالغة الانجليزية"
                                        filled
                                        name="details_en"
                                        v-model="form.details_en"
                                        auto-grow
                                        rows="4"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                            </v-row>


                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <InertiaLinkBtn
                                :url="route('phrases.index')"
                                class="v-btn v-size--default primary theme--light"
                                title="الرجوع للخلف"
                            />
                            <v-btn
                                type="submit"
                                :loading="loading"
                                color="primary"
                                >حفظ</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// npm cache clean --force
// npm config delete https-proxy
// php artisan cache:clear
// php artisan config:clear
// php artisan view:clear
// php artisan key:generate
import layout from "@/Layouts/Layout";
import { month_my, month_hj } from "@/data/temp.js";

import cTextField from "@/components/shared/cTextField";
import cTextarea from "@/components/shared/cTextarea";
export default {
    layout: layout,
    cTextarea: cTextarea,
    cTextField: cTextField,
    // props: [
    // ],
   components: {
        InertiaLinkBtn: () => import("@/components/InertiaLinkBtn")
    },
    watch: {
    //    category_id: function(v) {
    //         axios
    //             .get(route("git_sub_categories", this.category_id))
    //             .then(res => {
    //                 this.sub_categories = res.data.gitsubcategories;
    //             });
    //     }
    },


    metaInfo() {
        return {
            title: "اضافة منتج مقاطع"
        };
    },
    methods: {
        onChangeimage(event) {
            const main_image = event.target.files[0];
            this.form.main_image = main_image;
        },
        submit() {
            // let config = { headers: { "content-type": "multipart/form-data" } };
            this.$inertia.post(route("phrases.store"),this.form, {
                onProgress: progress => {
                    this.loading = true;
                },
                onSuccess: page => {
                    this.loading = false;
                },
                onFinish: () => {
                    this.loading = false;
                }
            });
        }
    },

     // title
    // details
    // said
    // title_en
    // details_en
    // said_en
    // show_before
    // show_hijris
    // day
    // month
    // show_alert
    // show_alert_time_in
        data() {
        return {
            loading: false,
            tab: null,
            month_my,
            month_hj,
            form: {
                title: "",
                title_en: "",
                details: "",
                details_en: "",
                said: "",
                said_en: "",
                show_before:false,
                show_hijris:true,
                show_alert:false,
                day: "",
                month: "",
                said_en: "",
                said_en: "",
                show_alert_time_in:"",

            },
        };
    },
};
</script>

<style></style>
