<template>
  <v-container>
    <v-card-title class="d-flex justify-center">الصلاحيات</v-card-title>

    <v-tabs v-model="tab" centered leave-absolute show-arrows height="80">
      <v-tab href="#create">انشاء</v-tab>
      <v-tab href="#read"> قراءة </v-tab>
      <v-tab href="#update">تعديل</v-tab>
      <v-tab href="#eye">ضهور</v-tab>
      <v-tab href="#delete"> حذف </v-tab>
      <v-tabs-items
        v-model="tab"
        background-color="transparent"
        class="transparent"
      >
        <v-container>
          <v-tab-item
            v-for="(code_permission, code) in permissions"
            :key="code"
            :value="code"
          >
            <v-row>
              <v-col
                cols="6"
                class="pa-0"
                md="4"
                lg="3"
                v-for="permission in code_permission"
                :key="permission.id"
              >
                <v-checkbox
                  :value="permission.id"
                  v-model="selected_permission"
                  :label="permission.display_name"

                ></v-checkbox>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-container>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
export default {
  //   props: ["permissions", "selected_permissions"],
  props: {
    permissions: Object,
    selected_permissions: Array,
  },

  computed: {
    selected_permission: {
      get() {
        return this.selected_permissions;
      },
      set(value) {
        this.$emit("update:selected_permissions", value);
      },
    },
  },
  data() {
    return {
      tab: null,
      //   selected_permission: this.selected_permissions || [],
    };
  },
  watch: {
    // selected_permission(value) {
    //   this.$emit("input", value);
    // },
  },
};
</script>

<style>
</style>
