<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2> التعديل  على الشركة المقاطعة  </h2>
      </v-col>
      <v-col cols="12" sm="12">
        <form @submit.prevent="submit"  enctype="multipart/form-data" >
          <v-card elevation="3">
             <v-col cols="12">
        <h2 class="text-center"> التعديل على الشركة المقاطعة {{form.name}}  </h2>
      </v-col><v-card-text>
            <v-row >
            <v-col cols="12" sm="12" md="12">
            <c-text-field
            label="اسم الصنف "
            name="name"
            v-model="form.name"/>
              </v-col>
            </v-row>


            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="تفاصيل  العلامة التجارية "
                                        filled
                                        name="details"
                                        v-model="form.details"
                                        auto-grow
                                        rows="4"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="تفاصيل العلامة التجارية بالغة الانجليزية"
                                        filled
                                        name="details_en"
                                        v-model="form.details_en"
                                        auto-grow
                                        rows="4"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="سبب مقاطعة العلامة التجارية  "
                                        filled
                                        name="reason_boycott"
                                        v-model="form.reason_boycott"
                                        auto-grow
                                        rows="2"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="سبب مقاطعة العلامة التجارية بالعة الانجليزية"
                                        filled
                                        name="reason_boycott_en"
                                        v-model="form.reason_boycott_en"
                                        auto-grow
                                        rows="2"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                            </v-row>
            <v-row >
             <v-col cols="12" sm="12" md="12">
                         <input
                        type="file"
                        name="image"
                        @change="onChangeimage"
                        />
                        </v-col>
            </v-row>

            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <InertiaLinkBtn
                :url="route('boycott_companies.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"/>
              <v-btn type="submit" :loading="loading" color="primary"
                >حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";
export default {
  layout: layout,
  props: ["boycott_companies"],
  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
  },
  metaInfo() {
    return {
      title: "تعديل علامة تجارية  ",
    };
  },
  methods: {
       onChangeimage(event){
         console.log(event.target.files[0])
        const image = event.target.files[0];
         this.form.image = image;
   },
    submit() {
      let config = {headers:{'content-type': 'multipart/form-data',}};

      if(this.form.image){
          this.$inertia.post(route("boycott_companies.update2"),this.form,config,{
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
      }else{
          this.$inertia.post(route("boycott_companies.update2"),this.form,{
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
      }
    },
  },
  data() {
    return {
      loading: false,
      form: {
        id: this.boycott_companies.id || "",
        name: this.boycott_companies.name || "",
        name_en:this.boycott_companies.name_en || "",
        details:this.boycott_companies.details || "",
        details_en:this.boycott_companies.details_en || "",
        reason_boycott:this.boycott_companies.name || "",
        reason_boycott_en:this.boycott_companies.reason_boycott || "",
        image:this.boycott_companies.image || "",
      },
    };
  },
};
</script>
<style>
</style>
