<template>
  <the-datatable
    title="الاصناف "
    :headers="headers"
    :nametable="nametable"
    :dataSource="prayer_times"
  >
  </the-datatable>
</template>

<script>
import layout from "@/Layouts/Layout";
export default {
  layout: layout,
  components: { TheDatatable: () => import("@/components/admin/TheDatatable") },
  props: ["prayer_times"],
  metaInfo() {
    return {
      title: "الاصناف",
    };
  },

  methods: {
    open(data) {
      this.$root.$confirm
        .open("حذف", "هل تريد حذف هذا الصنف ", { color: "red" })
        .then((confirm) => {
          if (confirm) {
            this.$inertia.delete(data, {
              preserveState: false,
            });
          }
        });
    },
  },
  data() {
    return {
      nametable: "prayer_times",
      headers: [
        {
          text: "الرقم ",
          align: "start",
          sortable: false,
          value: "id",
          width: "5%",
        },
        // locations_id
        // month
        // day
        // fajr
        // duhur
        // asr
        // maghrib
        // isha
        // amsek
        // tsph
        // sunrise
        { text: "المنطقة ", value: "locations_id", width: "5%", align: "center" },
        { text: "الشهر", value: "month", width: "5%", align: "center" },
        { text: "اليوم ", value: "day", width: "5%", align: "center" },
        { text: "الفجر ", value: "fajr", width: "5%", align: "center" },
        { text: "الضهر ", value: "duhur", width: "5%", align: "center" },
        { text: "العصر ", value: "asr", width: "5%", align: "center" },
        { text: "المغرب ", value: "maghrib", width: "5%", align: "center" },
        { text: "العشاء ", value: "isha", width: "5%", align: "center" },
        { text: "الامساك ", value: "amsek", width: "5%", align: "center" },
        { text: "التسبيح ", value: "tsph", width: "5%", align: "center" },
        { text: "الشروق ", value: "sunrise", width: "5%", align: "center" },
        { text: "تفعيل", value: "eye", width: "5%", align: "center" },
        {
          text: "التحكم",
          value: "control",
          sortable: false,
          width: "20%",
          align: "center",
        },
      ],
    };
  },
};
</script>


<style>
</style>
