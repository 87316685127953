<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2 class="text-center">إضافة دور</h2>
      </v-col>
      <v-col cols="12">
        <form @submit.prevent="submit">
          <v-card elevation="3">
            <v-card-text>
              <v-text-field
                label="اسم الدور"
                name="name"
                outlined
                v-model="form.name"
                :class="{ 'is-invalid': errorFor('name') }"
                :error="!!errorFor('name')"
                :error-messages="errorFor('name')"
              />
              <v-text-field
                label=" الاسم المعروض"
                name="display_name"
                outlined
                v-model="form.display_name"
                :class="{ 'is-invalid': errorFor('display_name') }"
                :error="!!errorFor('display_name')"
                :error-messages="errorFor('display_name')"
              />
              <v-textarea
                label=" الوصف"
                name="description"
                outlined
                v-model="form.description"
                :class="{ 'is-invalid': errorFor('description') }"
                :error="!!errorFor('description')"
                :error-messages="errorFor('description')"
              >
              </v-textarea>

              <template v-if="permissions">
                <v-divider></v-divider>

                <Permissions
                  :permissions="permissions"
                  :selected_permissions.sync="form.selected_permission"
                />
              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer />

              <InertiaLinkBtn
                :url="route('roles.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"
              />
              <v-btn type="submit" :loading="loading" color="primary"
                >حفظ</v-btn
              >
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import layout from "@/Layouts/Layout";

export default {
  layout: layout,
  props: [
    // "names",
    "permissions",
  ],
  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
    Permissions: () => import("@/components/admin/Permissions"),
  },
  data() {
    return {
      tab: null,
      loading: false,
      form: {
        name: "",
        display_name: "",
        description: "",
        selected_permission: [],
      },
    };
  },
  methods: {
    submit() {
      this.loading = true;
      this.$inertia.post(route("roles.store"), this.form, {
        preserveScroll: true,
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
};
</script>

<style>
</style>
