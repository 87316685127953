// import firebase from 'firebase/app'
// import 'firebase/firestore'

// Initialize Firebase
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwbI0tFrjM6PiD65D3gbHu3L5yFZ99W6E",
  authDomain: "tapyan-81697.firebaseapp.com",
  databaseURL: "https://tapyan-81697-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "tapyan-81697",
  storageBucket: "tapyan-81697.appspot.com",
  messagingSenderId: "1023451489298",
  appId: "1:1023451489298:web:0667a9ec7eecd8d3dfa4cb",
  measurementId: "G-62NGY0KER2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
db.settings({ timestampsInSnapshots: true });

export default db;

