<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2> البنر </h2>
      </v-col>
      <v-col cols="12" sm="12">
        <form @submit.prevent="submit" enctype="multipart/form-data" method="POST" >
          <v-card elevation="3">
             <v-col cols="12">
        <h2 class="text-center">تعديل   الاعلان  </h2>
      </v-col><v-card-text>
        <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-text-field
                                        label="اسم المنطقة "
                                        name="name"
                                        v-model="form.name"
                                        clearable
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-text-field
                                        label="  اسم المنطقة بالغة الانجليزية  "
                                        name="name_en"
                                        v-model="form.name_en"
                                        clearable
                                    />
                                </v-col>
                            </v-row>


                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <c-text-field
                                        label="كود المنطقة"
                                        name="code"
                                        clearable
                                        v-model="form.code"
                                    />
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <c-text-field
                                        label="خطوط الطول"
                                        name="latitude"
                                        clearable
                                        v-model="form.latitude"
                                    />
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <c-text-field
                                        label="خطوط العرض "
                                        name="longitude"
                                        clearable
                                        v-model="form.longitude"
                                    />
                                </v-col>

                            </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <InertiaLinkBtn
                :url="route('locations.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"/>
              <v-btn type="submit" :loading="loading" color="primary"
                >حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";
export default {
  layout: layout,
  props: ["locations"],
  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
  },
  metaInfo() {
    return {
      title: "تعديل اعلان  ",
    };
  },
  methods: {
         onChangeimage(event){
         console.log(event.target.files[0])
        const image = event.target.files[0];
         this.form.image = image;
   },
    submit() {
    //   let config = {headers:{'content-type': 'multipart/form-data',}};
      this.$inertia.put(route("locations.update",this.form.id),this.form, {
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
  data() {
    return {
      loading: false,
      tab: null,
      form:this.locations,
    };
  },
};
</script>
<style>
</style>
