<template>
  <the-datatable
    title="المستخدمين"
    :nametable="nametable"
    :headers="headers"
    :dataSource="allusers"
  >
    <template v-slot:actions="{ link }">
      <v-menu
        v-if="can(['users-delete', 'users-update', 'users-read'])"
        offset-y
        origin="center center"
        :nudge-bottom="10"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            <v-icon>fa-bars</v-icon>
          </v-btn>
        </template>
        <v-list>
          <inertia-link
            class="v-list-item v-list-item--link theme--light d-flex justify-center"
            :href="`${link}`"
            v-if="can('users-read')"
            >عرض بيانات المستخدم
          </inertia-link>
          <inertia-link
            class="v-list-item v-list-item--link theme--light d-flex justify-center"
            :href="`${link}/edit`"
            v-if="can('users-update')"
            >تعديل
          </inertia-link>

          <v-list-item
            link
            @click="open(link)"
            light
            v-if="can('users-delete')"
          >
            <v-list-item-title class="d-flex justify-center"
              >حذف</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </the-datatable>
</template>

<script>
import layout from "@/Layouts/Layout";

// import TheDatatable from "@/components/admin/TheDatatable";
export default {
  layout: layout,
  components: { TheDatatable: () => import("@/components/admin/TheDatatable") },
  props: ["allusers"],
  metaInfo() {
    return {
      title: "المستخدمين",
    };
  },

  methods: {
    open(data) {
      this.$root.$confirm
        .open("حذف", "هل تريد حذف هذا المستخدم", { color: "red" })
        .then((confirm) => {
          if (confirm) {
            this.$inertia.delete(data, {
              preserveState: false,
            });
          }
        });
    },
  },
  data() {
    return {
      nametable: "users",
      headers: [
        {
            text: "ID",
            align: "start",
            sortable: false,
            value: "id",
            width: "3%",
        },
        { text: "اسم المستخدم", value: "name", width: "30%", align: "center" },
        {
          text: "البريد الالكتروني",
          value: "email",
          width: "30%",
          align: "center",
        },
        {
          text: "الضهور",
          value: "eye",
          sortable: false,
          width: "15%",
          align: "center",
        },
        {
          text: "التحكم",
          value: "control",
          sortable: false,
          width: "15%",
          align: "center",
        },
      ],
    };
  },
};
</script>


<style>
</style>
