<template>
    <the-datatable
    title="التقويم الهجري  "
    :headers="headers"
      :nametable="nametable"
       :dataSource="agricultural_calendars">



    </the-datatable>
</template>

<script>
import layout from "@/Layouts/Layout";

// import TheDatatable from "@/components/admin/TheDatatable";
export default {
    layout: layout,
    components: {TheDatatable: () => import("@/components/admin/TheDatatable")},
    props: ["agricultural_calendars"],
    metaInfo() {
        return {
            title: "التقويم الهجري",
        }
    },

    methods: {

    },
    data() {
        return {

               //     name
          //     title
          //     details
          //     starting_day
          //     starting_month
          //     end_day
          //end_month
      nametable: "agricultural_calendars",
            headers: [
                {
                    text: "الرقم ",
                    align: "start",
                    sortable: false,

                    value: "id",
                    width: "5%",
                },
                {text: " الاسم ", value: "name", width: "10%", align: "center"},
                {text: " العنوان ", value: "title", width: "20%", align: "center"},
                {text: " تفاصيل ", value: "details", width: "30%", align: "center"},
                {text: "شهر البداية", value: "starting_month", width: "5%", align: "center"},
                {text: "يوم البداية", value: "starting_day", width: "5%", align: "center"},
                {text: "شهر النهاية", value: "end_month", width: "5%", align: "center"},
                {text: "يوم البداية", value: "end_day", width: "5%", align: "center"},
                {text: "تفعيل", value: "eye", width: "5%", align: "center"},
                {
                    text: "التحكم",
                    value: "control",
                    sortable: false,
                    width: "20%",
                    align: "center",
                },
            ],
        };
    },
};
</script>


<style>

</style>
