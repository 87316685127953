<template>
  <v-container class="py-0" fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <h1 class="d-flex my-7 font-weight-light justify-center">

        </h1>

        <template>
  <div class="text-center">
    <v-btn
      :disabled="dialog"
      :loading="dialog"
      class="white--text"
      color="purple darken-2"
      click="dialog = true">
      Start loading
    </v-btn>
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
        <form @submit.prevent="submit">
          <v-card elevation="2" class="pa-5">
            <v-card-title
              class="d-flex my-7 justify-center primary--text"
            >
              تسجيل الدخول
            </v-card-title>
            <v-card-text>
              <c-text-field
              type="email"
                name="email"
                 label="البريد الالكتروني"
                append-icon="fa-envelope"
                 v-model="form.email"
               />

                <c-password

                name="password"
                  label="كلمة المرور"

                 v-model="form.password"
               />

            </v-card-text>
            <v-card-actions>
              <v-checkbox
                v-model="form.remember"
                label=" تذكرني"

              />
              <v-spacer />
              <v-btn
                dark
                depressed
                :loading="form.processing"
                color="primary"
                type="submit"
              >
                تسجيل الدخول
              </v-btn>
            </v-card-actions>


            <c-inertia-link
                class="primary--text"
                title="نسيت كلمة المرور"
             :href="route('password.request')"
              v-if="canResetPassword"
               />

          </v-card>
        </form>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import layout from "@/Layouts/AuthLayout";

import cTextField from "@/components/shared/cTextField";
import cPassword from "@/components/shared/cPassword";
export default {
  layout: layout,
  cTextField: cTextField,
  cPassword: cPassword,

  metaInfo() {
    return {
      title: "تسجيل الدخول",
    };
  },
  watch: {
      dialog (val) {
        if (!val) return

        setTimeout(() => (this.dialog = false), 4000)
      },
    },
  props: {
    canResetPassword: Boolean,
    status: String,
  },

  data() {
    return {
     dialog: false,
      form: this.$inertia.form({
        email: "",
        password: "",
        remember: false,
      }),
    };
  },

  methods: {
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          remember: this.form.remember ? "on" : "",
        }))
        .post(this.route("login"), {
          onFinish: () => this.form.reset("password"),
        });
    },
  },
};
</script>
