<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12">
                <h2>اضافة  حدث </h2>
            </v-col>
            <v-col cols="12" sm="12">
                <form @submit.prevent="submit"
                    enctype="multipart/form-data"
                    method="POST">
                    <v-card elevation="3">
                        <v-col cols="12">
                            <h2 class="text-center">إضافة  حدث جديد</h2>
                        </v-col>
                        <v-card-text>
                         <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        filled
                                        label="عنوان الحدث"
                                        name="text_event"
                                        v-model="form.text_event"
                                        auto-grow
                                        rows="4"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                    filled
                                    label="عنوان الحدث بالغة الانجليزية"
                                        name="text_event_en"
                                        v-model="form.text_event_en"
                                        clearable
                                        auto-grow
                                        rows="4"
                                        row-height="30"
                                        shaped
                                        outlined
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2" sm="2" md="2">
                                <v-checkbox
                                    label="العرض هجري"
                                    name="show_hijris"
                                    v-model="form.show_hijris"
                                    class="py-3 my-0"/>
                                </v-col>

                                <v-col v-if="form.show_hijris  == false" cols="3" sm="3" md="3">
                                <v-select
                                    :items="month_my"
                                    item-value="id"
                                    item-text="name"
                                    name="month"
                                   v-model="form.month"
                                    outlined
                                    dense
                                    clearable
                                    label=" الشهر الميلادي "/>
                                </v-col>

                                <v-col v-else cols="3" sm="3" md="3">
                                <v-select
                                    :items="month_hj"
                                    item-value="id"
                                    item-text="name"
                                    name="month"
                                   v-model="form.month"
                                    outlined
                                    dense
                                    clearable
                                    label=" الشهر الهجري "/>
                                </v-col>

                                <v-col  cols="3" sm="3" md="3">
                                <v-select
                                    :items="[
                                    1,
                                    2,
                                    3,
                                    4,
                                    5,
                                    6,
                                    7,
                                    8,
                                    9,
                                    10,
                                    11,
                                    12,
                                    13,
                                    14,
                                    15,
                                    16,
                                    17,
                                    18,
                                    19,
                                    20,
                                    21,
                                    22,
                                    23,
                                    24,
                                    25,
                                    26,
                                    27,
                                    28,
                                    29,
                                    30,
                                    31,
                                    ]"

                                     label= "اليوم"
                                    name="day"
                                    v-model="form.day"
                                    outlined
                                    dense
                                    clearable />
                                </v-col>


                                <v-col cols="2" sm="2" md="2">
                            <v-checkbox
                                label="تنبية الحدث"
                                name="show_alert"
                                v-model="form.show_alert"
                                class="py-3 my-0"/>
                            </v-col>
                            <v-col v-if="form.show_alert  == true" cols="2" sm="2" md="2">
                                    <c-text-field
                                        label="الوقت  "
                                        type="time"
                                        name="show_alert_time_in"
                                        v-model="form.show_alert_time_in"
                                        clearable
                                    />
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="4" sm="4" md="4">
                                    <c-text-field
                                        label="سنة الحدث"
                                        name="year"
                                        clearable
                                        type="number"
                                        v-model="form.year"
                                    />
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <c-text-field
                                        label="تاريخ بداية الحدث "
                                        name="date_start"
                                        clearable
                                        type="date"
                                        v-model="form.date_start"
                                    />
                                </v-col>
                                <v-col cols="4" sm="4" md="4">
                                    <c-text-field
                                        label="تاريخ نهاية الحدث "
                                        name="date_end"
                                        clearable
                                        type="date"
                                        v-model="form.date_end"
                                    />
                                </v-col>

                            </v-row>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <InertiaLinkBtn
                                :url="route('events.index')"
                                class="v-btn v-size--default primary theme--light"
                                title="الرجوع للخلف"
                            />
                            <v-btn
                                type="submit"
                                :loading="loading"
                                color="primary"
                                >حفظ</v-btn
                            >
                        </v-card-actions>
                    </v-card>
                </form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import layout from "@/Layouts/Layout";
import cTextField from "@/components/shared/cTextField";
import cTextarea from "@/components/shared/cTextarea";
import { month_my, month_hj } from "@/data/temp.js";
export default {
    layout: layout,
    cTextField: cTextField,
    cTextarea: cTextarea,
    props: [
    ],
    components: {
        InertiaLinkBtn: () => import("@/components/InertiaLinkBtn")
    },
    watch: {
    },
    metaInfo() {
        return {
            title: "اضافة منتج مقاطع  "
        };
    },
    methods: {
        submit() {
            // let config = { headers: { "content-type": "multipart/form-data" } };
            this.$inertia.post(route("events.store"),this.form, {
                onProgress: progress => {
                    this.loading = true;
                },
                onSuccess: page => {
                    this.loading = false;
                },
                onFinish: () => {
                    this.loading = false;
                }
            });
        }
    },
        data() {

    // text_event
    // date_start
    // date_end
    // text_event_en
    //day
    // month
    // year
    // show_hijris
    // show_alert
    // show_alert_time_in
        return {
            loading: false,
            tab: null,
            month_my,
            month_hj,
            form: {
                text_event: "",
                text_event_en: "",
                date_start: "",
                date_end: "",
                day: "",
                month: "",
                year: "",
                show_hijris:true,
                show_alert:false,
                show_alert_time_in: "",

            },
        };
    },
};
</script>

<style></style>
