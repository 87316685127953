<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2 class="text-center">تعديل مستخدم</h2>
      </v-col>
      <v-col cols="12" sm="9">
        <form @submit.prevent="submit">
          <v-card elevation="3">
            <v-card-text>
            <v-row>
                <v-col cols="6" sm="6" md="6">
                  <c-text-field
                    label="اسم المستخدم"
                    outlined
                    type="text"
                    name="name"
                    v-model="form.name"/>
                </v-col>
                    <v-col cols="6" sm="6" md="6">
                  <c-text-field
                    label="رقم الهاتف "
                    outlined
                    type="number"
                    name="phone"
                    v-model="form.phone"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="12">
                  <c-text-field
                    label="البريد الالكتروني"
                    outlined
                    name="email"
                    type="email"
                    v-model="form.email"
                  />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6" sm="6" md="6">
                 <Password
                v-model="form.password"
                name="password"
                dense
                label="كلمة المرور"
                :class="{ 'is-invalid': errorFor('password') }"
                :error="!!errorFor('password')"
                :error-messages="errorFor('password')"/>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                           <Password
                v-model="form.password_confirmation"
                name="password_confirmation"
                dense
                label=" تاكيد كلمة المرور "
                :class="{ 'is-invalid': errorFor('password_confirmation') }"
                :error="!!errorFor('password_confirmation')"
                :error-messages="errorFor('password_confirmation')" />
                </v-col>
            </v-row>
            <v-row>
                  <v-col cols="12" sm="12" md="12">
                 <v-select
                  v-if="can('roles-update')"
                v-model="form.role"
                :items="roles"
                  clearable
                outlined
                dense
                required
                item-text="display_name"
                item-value="id"
                :class="{ 'is-invalid': errorFor('role') }"
                :error="!!errorFor('role')"
                :error-messages="errorFor('role')"
                label="الدور "
              ></v-select>
                  </v-col>
            </v-row>
            <div v-if="form.role == 3">
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label="اسم الشركة "
                      outlined
                      type="text"
                      name="name_companies"
                      v-model="form.name_companies"
                    />
                  </v-col>
                   <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label=" العنوان  "
                      outlined
                      type="text"
                      name="addresse"
                      v-model="form.addresse"
                    />
                  </v-col>
                </v-row>
                <v-row>
                 <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label="رقم هاتف"
                      outlined
                      type="number"
                      name="mobile"
                      v-model="form.mobile"
                    />
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label=" رقم عاتف اخر"
                      outlined
                      type="number"
                      name="mobile2"
                      v-model="form.mobile2"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="ملاحظة  "
                      auto-grow
                      outlined
                      rows="6"
                      name="details"
                      row-height="15"
                      v-model="form.details"
                    >
                      {{ form.details }}
                    </v-textarea>
                  </v-col>
                </v-row>
            </div>
            <div v-if="form.role == 4">
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label="الاسم الاول  "
                      outlined
                      type="text"
                      name="fname"
                      v-model="form.fname"
                    />
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label="الاسم الاخير  "
                      outlined
                      type="text"
                      name="lname"
                      v-model="form.lname"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label=" العنوان   "
                      outlined
                      type="text"
                      name="addresse"
                      v-model="form.addresse"
                    />
                  </v-col>
                  <v-col cols="6" sm="6" md="6">
                    <c-text-field
                      label="هاتف اخر"
                      outlined
                      type="number"
                      name="mobile"
                      v-model="form.lname"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-textarea
                      label="ملاحظة  "
                      auto-grow
                      outlined
                      rows="6"
                      name="details"
                      row-height="15"
                      v-model="form.details"
                    >
                      {{ form.details }}
                    </v-textarea>
                  </v-col>
                </v-row>

            </div>
            <template v-if="permissions  ">
              <v-divider></v-divider>
              <Permissions
                :permissions="permissions"
                :selected_permissions.sync="form.selected_permission"
              />
            </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <InertiaLinkBtn
                :url="route('users.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"
              />
              <v-btn type="submit" :loading="loading" color="primary"
                >حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";
export default {
  layout: layout,
  props: [
    "userInfo",
    "roles",
    "permissions",
    "user_role",
    "user_permissions_ids",
  ],
  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
    Password: () => import("@/components/Password"),
    Permissions: () => import("@/components/admin/Permissions"),
  },
  metaInfo() {
    return {
      title: "تعديل مستخدم",
    };
  },
  methods: {
    submit() {
      this.$inertia.put(route("users.update",this.userInfo.id), this.form, {
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
  data() {
    return {
      loading: false,
      tab: null,
      showPassword: false,
      showPassword_confirm: false,
      form: {
        name: this.userInfo.name || "",
        email: this.userInfo.email || "",
        phone: this.userInfo.phone || "",
        details: this.userInfo.details || "",
        fname: this.userInfo.fname || "",
        lnane: this.userInfo.lname || "",
        mobile: this.userInfo.mobile || "",
        mobile2: this.userInfo.mobile2 || "",
        addresse: this.userInfo.addresse || "",
        password:"",
        password_confirmation: "",
        selected_permission: this.selected_permission || [],
        role: this.user_role ? this.user_role.id : null,
      },
    };
  },
};
</script>

<style>
</style>
