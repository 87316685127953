<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2>مواقيت الصلاة</h2>
      </v-col>
      <v-col cols="12" sm="12">
        <form
          @submit.prevent="submit"
          enctype="multipart/form-data"
          method="POST"
        >
          <v-card elevation="3">
            <v-col cols="12">
              <h2 class="text-center">إضافة موقيت صلاة جديد</h2> </v-col
            ><v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                                    <v-select
                                        :items="locations"
                                        v-model="form.locations_id"
                                        label="المنطقة "
                                        name="locations_id"
                                        item-text="name"
                                        item-value="id"
                                        dense
                                        clearable
                                        outlined
                                    />
                                </v-col>
              </v-row>


            <v-row>

                    <v-col v-if="form.locations_id  >=1 " cols="6" sm="6" md="6">
                    <v-select
                        :items="month_my"
                        item-value="id"
                        item-text="name"
                        name="month"
                    v-model="form.month"
                        outlined
                        dense
                        clearable
                        label=" الشهر الميلادي "/>
                    </v-col>

                    <v-col v-if="form.locations_id  >=1 "  cols="6" sm="6" md="6">
                    <v-select
                        :items="[
                        1,
                        2,
                        3,
                        4,
                        5,
                        6,
                        7,
                        8,
                        9,
                        10,
                        11,
                        12,
                        13,
                        14,
                        15,
                        16,
                        17,
                        18,
                        19,
                        20,
                        21,
                        22,
                        23,
                        24,
                        25,
                        26,
                        27,
                        28,
                        29,
                        30,
                        31,
                        ]"

                        label= "اليوم"
                        name="day"
                        v-model="form.day"
                        outlined
                        dense
                        clearable />
                    </v-col>
            </v-row>


              <v-row>
                <v-col v-if="form.locations_id  >=1 " cols="3" sm="3" md="3">
                                    <c-text-field
                                        label="توقيت صلاة الفجر  "
                                        type="time"
                                        name="fajr"
                                        v-model="form.fajr"
                                        clearable
                                    />
                                </v-col>

                                <v-col v-if="form.locations_id  >=1 " cols="3" sm="3" md="3">
                                    <c-text-field
                                        label="توقيت صلاة الضهر  "
                                        type="time"
                                        name="duhur"
                                        v-model="form.duhur"
                                        clearable
                                    />
                                </v-col>

                                <v-col v-if="form.locations_id  >=1 " cols="3" sm="3" md="3">
                                    <c-text-field
                                        label="توقيت صلاة العصر  "
                                        type="time"
                                        name="asr"
                                        v-model="form.asr"
                                        clearable
                                    />
                                </v-col>

                                <v-col v-if="form.locations_id  >=1 " cols="3" sm="3" md="3">
                                    <c-text-field
                                        label="توقيت صلاة المغرب  "
                                        type="time"
                                        name="maghrib"
                                        v-model="form.maghrib"
                                        clearable
                                    />
                                </v-col>

                                <v-col v-if="form.locations_id  >=1 " cols="3" sm="3" md="3">
                                    <c-text-field
                                        label="توقيت صلاة العشاء  "
                                        type="time"
                                        name="isha"
                                        v-model="form.isha"
                                        clearable
                                    />
                                </v-col>

                                <v-col v-if="form.locations_id  >=1 " cols="3" sm="3" md="3">
                                    <c-text-field
                                        label="توقيت  الامساك  "
                                        type="time"
                                        name="amsek"
                                        v-model="form.amsek"
                                        clearable
                                    />
                                </v-col>

                                <v-col v-if="form.locations_id  >=1 " cols="3" sm="3" md="3">
                                    <c-text-field
                                        label="توقيت  التسبيح  "
                                        type="time"
                                        name="tsph"
                                        v-model="form.tsph"
                                        clearable
                                    />
                                </v-col>

                                <v-col v-if="form.locations_id  >=1 " cols="3" sm="3" md="3">
                                    <c-text-field
                                        label="توقيت  الشروق  "
                                        type="time"
                                        name="sunrise"
                                        v-model="form.sunrise"
                                        clearable
                                    />
                                </v-col>



              </v-row>

            </v-card-text>
            <v-card-actions>
              <v-spacer />
             <InertiaLinkBtn
                :url="route('prayer_times.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"
              />
              <v-btn type="submit" :loading="loading" color="primary"
                >حفظ</v-btn
              >
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";
// import Input from "../../../Jetstream/Input.vue";
import cTextField from "@/components/shared/cTextField";
import cTextarea from "@/components/shared/cTextarea";
import { month_my } from "@/data/temp.js";
export default {
  layout: layout,
  cTextarea: cTextarea,
  cTextField: cTextField,
  props: [
        "locations",
    ],
  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
  },
  data() {

        // locations_id
        // month
        // day
        // fajr
        // duhur
        // asr
        // maghrib
        // isha
        // amsek
        // tsph
        // sunrise

    return {
      loading: false,
      locations: this.locations,
      month_my,
      form: {
        locations_id: 0,
        month: "",
        day: "",
        fajr: "",
        duhur: "",
        asr: "",
        maghrib: "",
        isha: "",
        amsek: "",
        tsph: "",
        sunrise: "",
      },
    };
  },

  metaInfo() {
    return {
      title: "اضافة صنف ",
    };
  },
  methods: {
    onChangeimage(event) {
      const image = event.target.files[0];
      this.form.image = image;
    },
    submit() {
    //   let config = { headers: { "content-type": "multipart/form-data" } };
      this.$inertia.post(route("prayer_times.store"), this.form, {
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
};
</script>

<style>
</style>
