<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2> التقويم الهجري</h2>
      </v-col>
      <v-col cols="12" sm="12">
        <form @submit.prevent="submit" enctype="multipart/form-data" method="POST" >
          <v-card elevation="3">
             <v-col cols="12">
        <h2 class="text-center">تعديل   على التقويم الهجري  </h2>
      </v-col><v-card-text>
          <v-row>
                              <v-col cols="4" sm="4" md="4">
                                                  <c-text-field
                                                       label="الربط بالتاريخ الميالادي "
                                                       name="calendar_tipyans_id"
                                                       v-model="form.calendar_tipyans_id"
                                                       clearable
                                                  />
                                             </v-col>
                         <v-col  cols="4" sm="4" md="4">
                         <v-select
                         :items="month_hj"
                         item-value="id"
                         item-text="name"
                         name="month"
                         v-model="form.month"
                         outlined
                         dense
                         clearable
                         label=" الشهر الهجري "/>
                         </v-col>

                         <v-col  cols="4" sm="4" md="4">
                         <v-select
                         :items="[
                         1,
                         2,
                         3,
                         4,
                         5,
                         6,
                         7,
                         8,
                         9,
                         10,
                         11,
                         12,
                         13,
                         14,
                         15,
                         16,
                         17,
                         18,
                         19,
                         20,
                         21,
                         22,
                         23,
                         24,
                         25,
                         26,
                         27,
                         28,
                         29,
                         30,
                         31,
                         ]"

                              label= "اليوم"
                         name="day"
                         v-model="form.day"
                         outlined
                         dense
                         clearable />
                         </v-col>

                         </v-row>
                            <v-row>
                         <v-col  cols="3" sm="3" md="3">
                                <v-select
                                    :items="yearhs"
                                    item-value="id"
                                    item-text="id"
                                    name="year"
                                   v-model="form.year"
                                    outlined
                                    dense
                                    clearable
                                    label="الربط بالسنة الهجرية "/>
                                </v-col>
                         <v-col  cols="3" sm="3" md="3">
                                <v-select
                                    :items="yearms"
                                    item-value="id"
                                    item-text="id"
                                    name="yearm"
                                   v-model="form.yearm"
                                    outlined
                                    dense
                                    clearable
                                    label="الربط بالسنة الميلادية"/>
                                </v-col>
                            </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <InertiaLinkBtn
                :url="route('calendar_hijris.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"/>
              <v-btn type="submit" :loading="loading" color="primary"
                >حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";

import { yearms, month_hj,yearhs } from "@/data/temp.js";
export default {
  layout: layout,
  props: ["calendar_hijris"],
  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
  },
  metaInfo() {
    return {
      title: "تعديل على التقويم الهجري ",
    };
  },
  methods: {
    submit() {
     //  let config = {headers:{'content-type': 'multipart/form-data',}};
      this.$inertia.put(route("calendar_hijris.update", this.form.id), this.form, {
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
  data() {
    return {
      loading: false,
      tab: null,
      month_hj,
     yearms,
     yearhs,
      form:this.calendar_hijris,
    };
  },
};
</script>
<style>
</style>
