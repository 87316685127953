<template>
  <v-container fluid fill-height class="invocie-page">
    <div class="invocie-page-cover"></div>
    <v-row justify="center" class="mb-10">
      <v-col cols="4" md="3" lg="2">
        <c-card
          @click="open_dialog('hotel_info')"
          title="عنوان"
          icon="fa-buliding"
        />
      </v-col>
      <v-col cols="4" md="3" lg="2">
        <c-card
          :href="route('users.index')"
          title="إدارة المستخدمين"
          icon="fa-users"
          color="red darken-1"
        />
      </v-col>
      <v-col cols="4" md="3" lg="2">
        <c-card
          :href="route('phrases.index')"
          color="yellow darken-5"
          icon="fa-comments"
          title="اعدادات العبارات"
        />
      </v-col>
      <v-col cols="4" md="3" lg="2">
        <c-card
          :href="route('prayer_times.index')"
          color="green darken-7"
          icon="fa-building"
          title="اعدادات مواقيب الصلاة"
        />
      </v-col>
      <v-col cols="4" md="3" lg="2">
        <c-card
          :href="route('boycott_products.index')"
          color="pink darken-1"
          icon="fa-calendar"
          title="عنوان"
        />
      </v-col>
      <v-col cols="4" md="3" lg="2">
        <c-card
          @click="open_dialog('reservation_settings')"
          title=" عنوان "
          icon="fa-buliding"
        />
      </v-col>
      <v-col cols="4" md="3" lg="2">
        <c-card
            @click="open_dialog('decades_settings')"
          color="blue darken-4"
          icon="fa-braille"
          title="عنوان "/>
      </v-col>

      <v-col cols="4" md="3" lg="2">
        <c-card
          :href="route('locations.index')"
          color="purple darken-1"
          icon="fa-bed"
          title="عنوان "
        />
      </v-col>
      <v-col cols="4" md="3" lg="2">
        <c-card
          :href="route('calendar_hijris.index')"
          color="black darken-1"
          icon="fa-cog"
          title="عنوان "
        />
      </v-col>

      <v-col cols="4" md="3" lg="2">
            <c-card
          :href="route('calendar_tepyans.index')"
           color="purple darken-1"
          icon="fa-money"
          title="عنوان "
        />
      </v-col>

      <v-col cols="4" md="3" lg="2">
        <c-card
          :href="route('events.index')"
          color="orange darken-1"
          icon="fa-calculator"
          title="عنوان "
        />
      </v-col>
      <v-col cols="4" md="3" lg="2">
        <c-card
          :href="route('calendar_tepyans.index')"
          color="red darken-1"
          icon="fa-gavel"
          title="عنوان"
        />
      </v-col>
      <v-col cols="4" md="3" lg="2">
        <c-card
          :href="route('calendar_tepyans.index')"
          color="brown darken-1"
          icon="fa-recycle"
          title="عنوان"
        />
      </v-col>
      <v-col cols="4" md="3" lg="2">
        <c-card
          :href="route('calendar_tepyans.index')"
          color="purple darken-1"
          icon="fa-users"
          title="عنوان"
        />
      </v-col>
      <v-col cols="4" md="3" lg="2">
        <c-card
          :href="route('calendar_tepyans.index')"
          color="green lighten-1"
          icon="fa-home"
          title="عنوان"
        />
      </v-col>
      <HotelSettion ref="hotel_info" />
      <ReservationSettings ref="reservation_settings" />
      <DecadesSettings ref="decades_settings" />
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";

export default {
  layout: layout,
  components: {
    HotelSettion: () => import("@/components/admin/Settings/hotel_info"),
    ReservationSettings: () =>
      import("@/components/admin/Settings/ReservationSettings"),
    DecadesSettings: () =>
      import("@/components/admin/Settings/DecadesSettings"),
  },
  methods: {
    open_dialog(ref) {
      this.$refs[ref].open();
    },
  },
  metaInfo() {
    return {
      title: this.pageTitle,
    };
  },
  data() {
    return {
      //  dialog: false,
      pageTitle: "الاعدادات",
    };
  },
};
</script>
<style scoped>
.invocie-page {
  position: relative;
  background: white;
}
.invocie-page-cover {
  position: absolute;
  background-image: url(/imgs/room.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  opacity: 0.08;
  top: -200px;
  left: 0;
  width: 100%;
  height: calc(100% + 200px);
}
</style>
