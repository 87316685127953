<template>
  <v-menu
    max-width="250"
    offset-y
    origin="center center"
    :nudge-bottom="10"
    transition="scale-transition" >
    <template v-slot:activator="{ on }">
      <div large text v-on="on" style="cursor: pointer">
        <span class="hidden-sm-and-down">
          {{ $page.props.user.name | minUserName }}

               <!-- {{ $page.props.auth.maza.user}} -->
                   <!-- {{ $vuetify.lang.tt('$vuetify.my-component.text') }} -->
        </span>
        <v-avatar size="30px">
          <!-- <img :src='require("@/static/02.jpg")' alt="Avatar" /> -->
          <!--                <img src="/imgs/person.svg" alt="Avatar" />          -->
          <img :src="`${$page.props.user.profile_photo_url}`" alt="Avatar" />
        </v-avatar>
      </div>
    </template>

    <v-list class="pa-0">
       <v-list-item >

       <v-list-item-action class="ml-3">
      <v-icon>fa-language</v-icon>
    </v-list-item-action>

    </v-list-item>
      <InertiaLinkBtn
        url="/user/profile"
        title="الملف الشخصي"
        icon="fa-user"
        color="theme--light  v-list-item"
      />
      <InertiaLinkBtn
        :url="UserLang"
        v-if="`${$page.props.user.lang}` == 'en'"

         :title="$vuetify.lang.t('$vuetify.badge')"
        icon="fa-language"
        color="theme--light  v-list-item"
      />
      <InertiaLinkBtn
        :url="UserLang"
        v-else
        title="$vuetify.lang.maza.lang_en"
        icon="fa-language"
        color="theme--light  v-list-item"
      />
      <InertiaLinkBtn
        url="/logout"
        method="post"
        as="button"
        title="  تسجيل الخروج"
        icon="fa-sign-out-alt"
        color="theme--light v-list-item w-100 "
      />
    </v-list>
  </v-menu>
</template>
<script>
import InertiaLinkBtn from "@/components/InertiaLinkBtn";
export default {
  props: {},
  components: {
    InertiaLinkBtn,
  },
  //   mounted() {
  //        axios.get(route("assets.lang")).then((qw) => {
  //          this.$vuetify.lang = qw.data.langer;
  //             });
  // },
  filters: {
    minUserName: function (value) {
       if (!value) return "";
       return value.slice(0, 15) + (value.length > 15 ? "..." : "");
    },
  },
  computed: {
    UserLang() {

    // axios.get(route("assets.lang")).then((qw) => {
    //      return  $vuetify.lang.tt(qw.data.langer);
    //           });
      // axios.get(route("assets.lang")).then((qw) => {
      //    return (this.$vuetify.lang = qw.data.langer);
      //         });
      if (this.$page.props.user.lang =='ar') {
     this.$vuetify.rtl = true;
     return '/lang/en';
      }else{
      this.$vuetify.rtl = false;
     return '/lang/ar';
      }
    },

  },

};
</script>

