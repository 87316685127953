<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2>الشركات</h2>
      </v-col>
      <v-col cols="12" sm="12">
        <form
          @submit.prevent="submit"
          enctype="multipart/form-data"
          method="POST"
        >
          <v-card elevation="3">
            <v-col cols="12">
              <h2 class="text-center">إضافة شركة جديد</h2> </v-col
            ><v-card-text>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <c-text-field
                    label="اسم الصنف "
                    name="name"
                    v-model="form.name"
                  />
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <c-text-field
                    label="اسم الصنف "
                    name="name_en"
                    v-model="form.name_en"
                  />
                </v-col>
              </v-row>

              <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="تفاصيل  المنتج "
                                        filled
                                        name="details"
                                        v-model="form.details"
                                        auto-grow
                                        rows="4"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="تفاصيل المنتج بالغة الانجليزية"
                                        filled
                                        name="details_en"
                                        v-model="form.details_en"
                                        auto-grow
                                        rows="4"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                 </v-row>

                            <v-row>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="سبب المقاطعة  "
                                        filled
                                        name="reason_boycott"
                                        v-model="form.reason_boycott"
                                        auto-grow
                                        rows="2"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="6" sm="6" md="6">
                                    <c-textarea
                                        label="سبب المقاطعة بالعة الانجليزية"
                                        filled
                                        name="reason_boycott_en"
                                        v-model="form.reason_boycott_en"
                                        auto-grow
                                        rows="2"
                                        row-height="30"
                                        shaped
                                        clearable
                                        outlined
                                    />
                                </v-col>
                            </v-row>

              <v-row>
                   <v-col cols="12" sm="12" md="12">
                  <input type="file" name="image" @change="onChangeimage" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <InertiaLinkBtn
                :url="route('boycott_companies.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"
              />
              <v-btn type="submit" :loading="loading" color="primary"
                >حفظ</v-btn
              >
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";
import Input from "../../../Jetstream/Input.vue";
export default {
  layout: layout,
  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
  },
  data() {
    return {
      loading: false,
      form: {
        name: "",
        name_en: "",
        details: "",
        details_en: "",
        reason_boycott: "",
        reason_boycott_en: "",
        image: "",
      },
    };
  },

  metaInfo() {
    return {
      title: "اضافة علامة تجارية  ",
    };
  },
  methods: {

    onChangeimage(event) {
     console.log(event.target.files[0])
      const image = event.target.files[0];
      this.form.image = image;
    },
    submit() {
      let config = { headers: { "content-type": "multipart/form-data" } };
      this.$inertia.post(route("boycott_companies.store"), this.form, config, {
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
};
</script>

<style>
</style>
