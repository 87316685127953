<template>
    <the-datatable
    title="البنر  "
    :headers="headers"
      :nametable="nametable"
       :dataSource="locations">
    </the-datatable>
</template>

<script>
import layout from "@/Layouts/Layout";

// import TheDatatable from "@/components/admin/TheDatatable";
export default {
    layout: layout,
    components: {TheDatatable: () => import("@/components/admin/TheDatatable")},
    props: ["locations"],
    metaInfo() {
        return {
            title: "البنر",
        }
    },

    methods: {

    },
    data() {
        return {

      nametable: "locations",
            headers: [
                {
                    text: "الرقم ",
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "5%",
                },
                {text: "الاسم ", value: "name", width: "20%", align: "center"},
                {text: "الاسم بالغة الانجليزية", value: "name_en", width: "20%", align: "center"},
                { text: "كود المنطقة ", value: "code", width: "5%", align: "center" },
                { text: "نقاط الطول  ", value: "latitude", width: "10%", align: "center" },
                { text: " نقاط العرظ ", value: "longitude", width: "10%", align: "center" },
                {text: "تفعيل", value: "eye", width: "10%", align: "center"},
                {
                    text: "التحكم",
                    value: "control",
                    sortable: false,
                    width: "20%",
                    align: "center",
                },
            ],
        };
    },
};
</script>


<style>

</style>
