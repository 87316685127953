export default {
    mounted(){
        this.$nextTick(()=>{
            this.updateTime()
            setInterval(this.updateTime, 1000);
        })
    },
    data(){
        return {
            ctime:"",
            cdate:"",
            cweek : ['الاحد', 'الاثنين', 'الثلاثاء', 'الاربعاء', 'الخميس', 'الجمعة', 'السبت']
        }
    },
    methods: {

        updateTime() {
            var cd = new Date();
            this.ctime = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2);
            this.cdate = this.zeroPadding(cd.getFullYear(), 4) + '-' + this.zeroPadding(cd.getMonth()+1, 2) + '-' + this.zeroPadding(cd.getDate(), 2) + ' ,' + this.cweek[cd.getDay()];
        },
        zeroPadding(num, digit) {
            var zero = '';
            for(var i = 0; i < digit; i++) {
                zero += '0';
            }
            return (zero + num).slice(-digit);
        },

    },

};
