<template>
  <v-app>
    <navbar />
    <v-main>
      <div class="py-7 white d-flex justify-end">
        <span class="mx-4"> {{ctime}}</span>
        <span class="mx-4"> {{ cdate }} </span>
        <!-- <span class="mx-4">      {{ trans('messages.project_in_progress') }} </span> -->
        <!-- <span class="mx-4">{{ langer.messages.create_cutomer }}  </span> -->
      </div>

      <slot />
      <confirm ref="confirm"></confirm>
    </v-main>
  </v-app>
</template>


<script>
//const lang = route('js/lang.js');

import navbar from "@/components/admin/navigation/TheAdminNav";
import Confirm from "@/components/Confirm.vue";
export default {
  components: { navbar, Confirm  },

  metaInfo() {
    return {
      title: "اسم الموقع",
        titleTemplate: (title) => title ? `${title} - ${this.$page.props.company.name}` : `${this.$page.props.company.name}`
    };
  },



  mounted() {
    this.$root.$confirm = this.$refs.confirm;

        //  axios.get(route("assets.lang")).then((qw) => {
        //     this.langer = qw.data.langer;
        //       });
  },
};
</script>
<style  >
.v-main {
  background-color: #efefef;
}
</style>
