<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <h2> الاعلانات </h2>
      </v-col>
      <v-col cols="12" sm="12">
        <form @submit.prevent="submit" enctype="multipart/form-data" method="POST" >
          <v-card elevation="3">
             <v-col cols="12">
        <h2 class="text-center">تعديل   الاعلان  </h2>
      </v-col><v-card-text>
            <v-row >
              <v-col cols="6" sm="6" md="6">
            <c-text-field
            label=" عنوان الاعلان  "
            name="title"
            v-model="form.title"/>
              </v-col>

                
              <v-col cols="6" sm="6" md="6">
            <c-text-field
            label=" رابك الاعلان  "
            name="url"
            v-model="form.url"/>
              </v-col>
               </v-row >

                  <v-row >
              <v-col cols="6" sm="6" md="6">
              <c-text-field
            label=" تاريخ بداية الاعلان "
            type="date"
            name="date_start"
            v-model="form.date_start"/>
              </v-col>
              <v-col cols="6" sm="6" md="6">
            <c-text-field
            label=" تاريخ نهاية الاعلان "
            type="date"
            name="date_end"
            v-model="form.date_end"/>
              </v-col>

            </v-row>
            <v-row >
                <v-col cols="6" sm="6" md="6">
                   <input type="file" name="image" @change="onChangeimage" />
                        </v-col>
                <v-col cols="6" sm="6" md="6">
            <c-text-field
            label="الترتيب"
            name="order"
            v-model="form.order"/>
              </v-col>
            </v-row> <v-row >
                <v-col cols="12" sm="12" md="12">
                   
            <c-text-field
            label="تفاصيل "
            name="describtion"
            v-model="form.describtion"/>
              </v-col>
            </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <InertiaLinkBtn
                :url="route('sliders.index')"
                class="v-btn v-size--default primary theme--light"
                title="الرجوع للخلف"/>
              <v-btn type="submit" :loading="loading" color="primary"
                >حفظ</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import layout from "@/Layouts/Layout";
export default {
  layout: layout,
  props: ["sliders"],
  components: {
    InertiaLinkBtn: () => import("@/components/InertiaLinkBtn"),
  },
  metaInfo() {
    return {
      title: "تعديل اعلان  ",
    };
  },
  methods: {
         onChangeimage(event){
         console.log(event.target.files[0])
        const image = event.target.files[0];
         this.form.image = image;
   },
    submit() {
      let config = {headers:{'content-type': 'multipart/form-data',}};
      this.$inertia.put(route("sliders.update", this.form.id), this.form,config, {
        onProgress: (progress) => {
          this.loading = true;
        },
        onSuccess: (page) => {
          this.loading = false;
        },
        onFinish: () => {
          this.loading = false;
        },
      });
    },
  },
  data() {
    return {
      loading: false,
      tab: null,
      form:this.sliders,
    };
  },
};
</script>
<style>
</style>
