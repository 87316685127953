<template>
    <the-datatable
    title="الأحداث"
    :headers="headers"
      :nametable="nametable"
       :dataSource="events">



    </the-datatable>
</template>

<script>
import layout from "@/Layouts/Layout";

// import TheDatatable from "@/components/admin/TheDatatable";
export default {
    layout: layout,
    components: {TheDatatable: () => import("@/components/admin/TheDatatable")},
    props: ["events"],
    metaInfo() {
        return {
            title: "المنتجات المقاطعة ",
        }
    },

    methods: {

    },
    data() {
        return {



     // text_event
    // date_start
    // date_end
    // text_event_en
    //day
    // month
    // year
    // show_hijris
    // show_alert
    // show_alert_time_in
      nametable: "events",
            headers: [
                {
                    text: "الرقم ",
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "5%",
                },
                {text: " الحدث ", value: "text_event", width: "55%", align: "center"},
                {text: "اليوم", value: "day", width: "5%", align: "center"},
                {text: "الشهر", value: "month", width: "5%", align: "center"},
                {text: "السنة", value: "year", width: "5%", align: "center"},
                {text: "تفعيل", value: "eye", width: "5%", align: "center"},
                {
                    text: "التحكم",
                    value: "control",
                    sortable: false,
                    width: "20%",
                    align: "center",
                },
            ],
        };
    },
};
</script>


<style>

</style>
