<template>
    <the-datatable
    title="العبارات"
    :headers="headers"
      :nametable="nametable"
       :dataSource="phrases">
    </the-datatable>
</template>

<script>
import layout from "@/Layouts/Layout";

// import TheDatatable from "@/components/admin/TheDatatable";
export default {
    layout: layout,
    components: {TheDatatable: () => import("@/components/admin/TheDatatable")},
    props: ["phrases"],
    metaInfo() {
        return {
            title: " العبارات  ",
        }
    },
    methods: {
    },
    data() {
        return {
      nametable: "phrases",
            headers: [
                {
                    text: "الرقم ",
                    align: "start",
                    sortable: false,
                    value: "id",
                    width: "5%",
                },
     // title
    // details
    // said
    // date
    // title_en
    // details_en
    // said_en
    // show_before
    // show_hijris
    // day
    // month
    // show_alert
    // show_alert_time_in

                {text: "العنوان ", value: "title", width: "10%", align: "center"},
                {text: "القائل", value: "said", width: "10%", align: "center"},
                {text: "اليوم", value: "day", width: "2%", align: "center"},
                {text: "الشهر", value: "month", width: "2%", align: "center"},
                { text: "تفاصيل ", value: "details", width: "36%", align: "center" },
                { text: "اصهر هجري ", value: "show_hijris", width: "3%", align: "center" },
                { text: "امامية", value: "show_before", width: "2%", align: "center" },
                {text: "تفعيل", value: "eye", width: "10%", align: "center"},
                {
                    text: "التحكم",
                    value: "control",
                    sortable: false,
                    width: "20%",
                    align: "center",
                },
            ],
        };
    },
};
</script>


<style>

</style>
